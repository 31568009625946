const About = () => {
  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        <div className="self-stretch flex flex-col pt-[0rem] px-[1.88rem] pb-[6.25rem] items-center justify-start text-center text-[4rem] lg:pb-[4.38rem] lg:box-border large_mobile:pl-[0.94rem] large_mobile:pr-[0.94rem] large_mobile:box-border Small_tablet:pb-[4.38rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl [background:linear-gradient(180deg,_#e7f4fa,_rgba(255,_255,_255,_0))] flex flex-col pt-[10.63rem] px-[15.63rem] pb-[0rem] items-center justify-start lg:pl-[9.38rem] lg:pr-[9.38rem] lg:box-border large_mobile:pt-[3.75rem] large_mobile:box-border Small_Desktop:pl-[0rem] Small_Desktop:pr-[0rem] Small_Desktop:box-border Small_tablet:pt-[5rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-row items-start justify-start large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border">
              <b className="flex-1 relative leading-[4.38rem] Small_tablet:text-[2.19rem] Small_tablet:leading-[2.81rem]">
                <span>{`Nous ne faisons pas que de la `}</span>
                <span className="text-darkcyan">télémédecine</span>
                <span>{`, nous nous reconnectons à l’essentiel : `}</span>
                <span className="text-chocolate">la santé</span>
              </b>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap p-[3.13rem] items-center justify-between large_mobile:gap-[3.13rem] large_mobile:pt-[1.88rem] large_mobile:px-[1.25rem] large_mobile:pb-[3.13rem] large_mobile:box-border Small_Desktop:gap-[3.13rem] Small_Desktop:items-center Small_Desktop:justify-center Small_tablet:pt-[1.25rem] Small_tablet:box-border">
          <div className="relative w-[6.44rem] h-[4.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[6.44rem] h-[4.06rem] object-cover"
              alt=""
              src="/1200pxlogotype-de-la-collectivite-territoriale-de-martinique-1@2x.png"
            />
          </div>
          <div className="relative w-[14.25rem] h-[3.63rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[14.25rem] h-[3.63rem] object-cover"
              alt=""
              src="/prosanteconnect-1@2x.png"
            />
          </div>
          <div className="relative w-[9.64rem] h-[1.91rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logosynapse@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[11.7rem] h-[2.27rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logostellair@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[8.56rem] h-[2.13rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[8.56rem] h-[2.13rem] object-cover"
              alt=""
              src="/logo-withings-1@2x.png"
            />
          </div>
          <div className="relative w-[9.75rem] h-[2.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[9.75rem] h-[2.06rem] object-cover"
              alt=""
              src="/mssante1-2@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-[1rem] large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">Le projet</div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                En constante évolution vers une médecine toujours plus proche de
                nos patients
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[150%] font-medium md:text-[1.13rem]">
                Conçus par et pour les professionnels de santé libéraux, les
                patients atteints de maladies chroniques et les aidants, Madoc
                Santé a pour mission de mettre davantage en valeur les données
                médicales, d’améliorer la coordination des soins et de prévenir
                les risques de décompensations.
              </div>
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_home_page_pro.svg"
          />
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border md:pl-[1.25rem] md:pr-[1.25rem] md:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border Small_tablet:pt-[3.13rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl flex flex-col p-[5rem] items-center justify-start gap-[2.44rem] border-[1px] border-solid border-darkorchid lg:pl-[3.75rem] lg:pr-[3.75rem] lg:box-border md:pl-[1.25rem] md:pr-[1.25rem] md:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border large_mobile:pt-[2.5rem] large_mobile:pb-[2.5rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
            <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold Small_tablet:text-[1rem]">
                Ce qui nous anime 🚀
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start text-center text-[3.13rem]">
              <b className="self-stretch relative leading-[3.44rem] Small_Desktop:text-[1.88rem] Small_Desktop:leading-[2.19rem]">
                Prendre de meilleures décisions pour sauver des vies
              </b>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-prosanteconnect@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Authentification sécurisée
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Pro Santé Connect est une solution intégrée sur notre
                    plateforme afin de pouvoir authentifier les soignants
                    inscrits et garantir la sécurité des patients.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-synapse@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Aide à la prescription
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Synapse Plateforme outil d’aide à la prescription pour
                    renforcer la sécurité de la prise en charge médicamenteuse.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-stellair@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Télétransmission
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Stellair Integral conçu pour tous les professionnels de
                    santé libéraux cette solution permet la facturation et la
                    télétransmission en mobilité et en cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/e-planning@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">{`E-planning `}</b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un agenda interactif pour planifier ses rendez-vous patients
                    en cabinet, à domicile ou en visioconférence et organiser
                    ses évènements professionnels.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-teleconsultation@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Téléconsultation
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Madoc Santé a intégré son propre module de téléconsultation.
                    Vos patients pourront donc obtenir un avis médical sur leur
                    état de santé sans se déplacer au cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-dossier-medical@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Suivi médical partagé
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un dossier du patient accessible à toute l’équipe médicale
                    du patient en accès temporaire ou permanent, pour faciliter
                    la prise de décision collégiale des soignants.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch relative leading-[150%] whitespace-pre-wrap md:text-[1rem]">
              <p className="m-0">
                <span className="font-medium font-metropolis text-darkslateblue">
                  En tant que médecins, soignants, dans les secteurs médicaux et
                  paramédicaux, nous sommes tous confrontés au même constat : “
                </span>
                <b className="font-metropolis text-chocolate">
                  Nous manquons d’informations médicales fiables
                </b>
                <span className="font-medium">{`”. `}</span>
              </p>
              <p className="m-0 font-medium">
                Il est donc parfois difficile de prendre des décisions justes et
                adaptées pour qu’elles aient un impact positif et durable sur
                chacun de nos patients.
              </p>
              <p className="m-0 font-medium">&nbsp;</p>
              <p className="m-0">
                <span className="font-medium">{`Selon notre étude menée en 2021 sur le territoire de la Martinique, `}</span>
                <b className="font-metropolis text-chocolate">
                  47% des patients
                </b>
                <span className="font-medium font-metropolis text-darkslateblue">{` suivi pour pathologie chronique `}</span>
                <b className="font-metropolis text-chocolate">
                  déclarent n’avoir pas été pris en charge correctement depuis
                  le début de leurs maladies
                </b>
                <span className="font-medium font-metropolis text-darkslateblue">{`. `}</span>
              </p>
              <p className="m-0 text-chocolate">
                <span className="font-medium font-metropolis text-darkslateblue">{`De plus, `}</span>
                <b className="font-metropolis text-chocolate">
                  1 médecin sur 3
                </b>
                <span className="font-medium font-metropolis text-darkslateblue">{`, implanté aussi bien les zones urbaines et qu’en zones rurales `}</span>
                <b>
                  déclarent n’avoir trouvé aucun remplaçant pour leur succéder
                  après leur départ à la retraite.
                </b>
              </p>
              <p className="m-0 font-medium">&nbsp;</p>
              <p className="m-0 font-medium">Notre souhait est de :</p>
              <p className="m-0 font-medium">
                ⌚️ Favoriser la collecte et la centralisation de données
                médicales grâce aux nouvelles technologies
              </p>
              <p className="m-0 font-medium">{`🍂  Trouver des alternatives aux déserts médicaux pour répondre aux difficultés d’accès aux soins des patients `}</p>
              <p className="m-0 font-medium">
                💻 Proposer des solutions pour diminuer la fracture numérique
                chez les personnes âgées et isolées
              </p>
              <p className="m-0 font-medium">&nbsp;</p>
              <p className="m-0 font-medium">{`Voilà donc les principales raisons parmi tant d’autres qui nous ont poussé à créer Madoc Santé, `}</p>
              <p className="m-0">
                <span className="font-medium">{`pour une `}</span>
                <b className="font-metropolis text-chocolate">
                  médecine plus précise, prédictive, préventive et innovante
                </b>
                <span className="font-medium font-metropolis text-darkslateblue">{`. `}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[7.5rem] items-center justify-start text-[1.5rem] text-darkcyan lg:pl-[1.25rem] lg:pr-[1.25rem] lg:box-border large_mobile:pt-[1.88rem] large_mobile:pb-[1.88rem] large_mobile:box-border">
          <div className="self-stretch rounded-xl bg-aliceblue flex flex-col py-[3.75rem] px-[7.5rem] items-center justify-start gap-[4.13rem] sm:gap-[2.5rem] Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-row items-center justify-center text-center text-[2.5rem] text-darkslateblue">
              <b className="flex-1 relative leading-[120%] sm:text-[1.88rem] Small_Desktop:text-[1.88rem]">
                <span>{`L’innovation au service de votre `}</span>
                <span className="text-chocolate">santé</span>
              </b>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_empathie.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1rem]">
                    Empathie
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[1rem] Small_tablet:text-[0.88rem]">{`L’empathie et la bienveillance sont des valeurs essentielles pour comprendre les besoins des patients, la charge mentale des aidants et les conditions de travail des soignants. C’est donc dans cette perspective que nous les avons tous inclus dans notre processus de conception. `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_coordination.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1rem]">
                    Coordination
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[1rem] Small_tablet:text-[0.88rem]">{`Pour une meilleure prise en charge et un suivi adapté à chaque patient, nous mettons tous nos efforts dans le développement d’un parcours de soin où l’équipe médicale et l’aidant ont un impact significatif sur la santé du patient.  `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_securite_a_propos.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1rem]">
                    Sécurité
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem] text-darkslategray">
                  <div className="flex-1 text-darkslateblue relative leading-[155%] font-medium Small_Desktop:text-[1rem] Small_tablet:text-[0.88rem]">
                    La confiance est essentielle dans le secteur de la santé.
                    Concevoir une plateforme fiable, sécurisée et inter-opérable
                    est un coeur de notre projet. Chaque dossier patient est
                    confidentiel, toutes les données sont hébergées sur des
                    serveurs certifiés HDS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
