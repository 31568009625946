import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Newsletters = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [buttonText, setButtonText] = useState("Je m'inscris");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [link, setLink] = useState("");

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);

    if (option === "je m'incris pro") {
      setButtonText("Je m'inscris sur le portail pro");
      setLink("https://pro.madocsante.fr");
      setButtonDisabled(false);
    } else if (option === "je m'incris patient") {
      setButtonText("Je m'inscris sur le portail patient");
      setLink("https://portail.madocsante.fr");
      setButtonDisabled(false);
    }
  };

  return (
    <section className="pt-10 mx-4 text-center _lg:text-left _lg:pt-4">
      <div className="w-full py-10 mx-auto bg-white rounded-3xl _lg:pt-14 _lg:pb-12 _lg:pl-14 _lg:pr-10">
        <div className="flex flex-col space-y-10 _lg:flex-row _lg:justify-between _lg:space-y-0">
          {/* text */}
          <div className="">
            <h3 className="text-xl font-semibold text-darkslateblue _lg:text-[28px] max-w-[427px]">
              Faites l’expérience du <br />{" "}
              <span className="text-darkcyan">parcours de soins</span> connecté
            </h3>
          </div>
          {/* fomulaire Newsletters */}
          <div className="max-w-[300px] flex flex-col text-md font-bold text-stetoscope _lg:space-y-0 _lg:flex-row _lg:max-w-[1200px]">
            <div className="flex flex-col _lg:flex-row">
              {/* radio button */}
              <div className="flex flex-col space-y-3 radio">
                <div className="flex flex-col space-y-3 _lg:flex-row _lg:space-x-2 _lg:space-y-0">
                  <p className="text-xl text-left text-darkslateblue">
                    Je suis :
                  </p>
                  <div className="flex items-center justify-between space-x-3">
                    <input
                      type="radio"
                      name="option"
                      value="je m'incris pro"
                      aria-label="professionnel de santé"
                      checked={selectedOption === "je m'incris pro"}
                      onChange={handleOptionChange}
                    />
                    <label className="text-lg text-darkslateblue">
                      Professionnel de santé
                    </label>
                  </div>
                  <div className="flex items-center justify-between space-x-3">
                    <input
                      type="radio"
                      aria-label="patient"
                      name="option"
                      value="je m'incris patient"
                      checked={selectedOption === "je m'incris patient"}
                      onChange={handleOptionChange}
                    />
                    <label className="text-lg text-darkslateblue">
                      Patient et Aidant
                    </label>
                  </div>
                </div>
                <div
                  className={
                    buttonDisabled
                      ? `text-white text-lg text-center cursor-not-allowed  border-solid border-4 border-[#ffeada] textde max-w-[350px] rounded-lg py-3`
                      : `text-darkslateblue text-lg text-center bg-sparadra max-w-[350px] rounded-lg py-3`
                  }
                >
                  <button
                    disabled={buttonDisabled}
                    onClick={event =>  {window.location.href=link}}
                    className= { `bg-transparent cursor-${
                      buttonDisabled ? "not-allowed text-chocolate" : "pointer text-white"
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                  {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* tast conterainer important pour le tast */}
      <ToastContainer />
    </section>
  );
};

export default Newsletters;
