import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface MenuMobileProps {
  onClose: () => void;
}


const MenuMobile = (MenuMobileProps) => {
  const navigate = useNavigate();

  const onTitreContainerClick = useCallback(() => {
    navigate("/professionnels-de-sante");
    MenuMobileProps.onClose();
  }, [navigate]);

  const onTitreContainer1Click = useCallback(() => {
    navigate("/patients");
    MenuMobileProps.onClose();
  }, [navigate]);

  const onTitreContainer2Click = useCallback(() => {
    navigate("/aidants");
    MenuMobileProps.onClose();
  }, [navigate]);

  const onTitreContainer3Click = useCallback(() => {
    navigate("/abonnements");
    MenuMobileProps.onClose();
  }, [navigate]);

  const onTitreContainer4Click = useCallback(() => {
    navigate("/a-propos");
    MenuMobileProps.onClose();
  }, [navigate]);

  const onTitreContainer5Click = useCallback(() => {
    navigate("/contact");
    MenuMobileProps.onClose();
  }, [navigate]);

  return (
    <div className="self-stretch rounded-t-none rounded-b-xl bg-white shadow-[0px_50px_50px_rgba(9,_38,_81,_0.1)] w-[18.50rem] flex flex-col py-[0.94rem] pl-3 box-border items-center justify-start gap-[5rem] max-h-full text-left text-[1rem] text-darkslateblue font-metropolis">
      <div className="self-stretch  flex flex-col items-start justify-start gap-[0.63rem]">
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
          onClick={onTitreContainerClick}>
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect1@2x.png"
          />
          <div className="flex flex-col items-start self-stretch justify-center flex-1">
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[155%] font-medium">
                Professionnels de santé
              </div>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
          onClick={onTitreContainer1Click}>
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect2@2x.png"
          />
          <div className="flex flex-col items-start self-stretch justify-center flex-1">
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[155%] font-medium">
                Patients
              </div>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
          onClick={onTitreContainer2Click}>
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect3@2x.png"
          />
          <div className="flex flex-col items-start self-stretch justify-center flex-1">
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[155%] font-medium">
                Aidants
              </div>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
          onClick={onTitreContainer3Click}>
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect4@2x.png"
          />
          <div className="flex flex-col items-start self-stretch justify-center flex-1">
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[155%] font-medium">
                Abonnements Pro
              </div>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
          onClick={onTitreContainer4Click}>
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect5@2x.png"
          />
          <div className="flex flex-col items-start self-stretch justify-center flex-1">
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[155%] font-medium">
                À propos
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center self-stretch justify-start">
          <div className="self-stretch flex-1 flex flex-row items-center justify-start gap-[1.25rem]">
            <img
              className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
              alt=""
              src="/icon-prosanteconnect6@2x.png"
            />
            <div className="flex flex-row items-start justify-start">
              <div className="relative leading-[155%] font-medium">Blog</div>
            </div>
            <div className="rounded bg-chocolate flex flex-row p-[0.31rem] items-start justify-start text-center text-[0.88rem] text-white">
              <div className="relative font-semibold">Bientôt disponible</div>
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
          onClick={onTitreContainer5Click}>
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect7@2x.png"
          />
          <div className="flex flex-col items-start self-stretch justify-center flex-1">
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[155%] font-medium">
                Contact
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-lg bg-antiquewhite flex flex-row py-[0.94rem] px-[0.75rem] items-start justify-start text-center text-chocolate">
        <div  onClick={onTitreContainer5Click} className="relative font-semibold">Demander une Démo</div>
      </div>
    </div>
  );
};

export default MenuMobile;
