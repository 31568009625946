import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Helpers = () => {
  const navigate = useNavigate();

  const homeNavigation = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const subscriptionNavigation = useCallback(() => {
    navigate("/abonnements");
  }, [navigate]);

  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        <div className="self-stretch flex flex-col pt-[0rem] px-[1.88rem] pb-[6.25rem] items-center justify-start lg:pb-[4.38rem] lg:box-border large_mobile:pl-[0.94rem] large_mobile:pr-[0.94rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:pb-[4.38rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl [background:linear-gradient(180deg,_#f5ebff,_rgba(255,_255,_255,_0))] flex flex-col pt-[5rem] px-[0rem] pb-[0rem] items-center justify-start gap-[1.88rem] large_mobile:pt-[3.75rem] large_mobile:box-border">
            <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] sm:hidden Small_Mobile:hidden Iphone_SE:hidden large_mobile:hidden Small_tablet:hidden">
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">⌚️</div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Suivi des constantes
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Coordination des soins
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">🧡</div>
              </div>
            </div>
            <div className="self-stretch flex flex-col py-[0rem] px-[9.38rem] items-center justify-start gap-[1.69rem] text-center text-[4rem] large_mobile:pl-[0rem] large_mobile:box-border Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border">
              <div className="self-stretch flex flex-row items-start justify-start large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border">
                <b className="flex-1 relative leading-[4.38rem] md:text-[2.81rem] md:leading-[3.44rem] sm:text-[2.19rem] sm:leading-[2.81rem]">
                  <span>{`Accompagner vos proches vers la médecine `}</span>
                  <span className="text-darkorchid">de demain</span>
                </b>
              </div>
              <div className="self-stretch flex flex-row items-center justify-center text-[1.5rem]">
                <div className="flex-1 relative leading-[2.5rem] font-medium md:text-[1.5rem] sm:text-[1.25rem] sm:leading-[1.88rem] Small_Mobile:text-[1.25rem] Iphone_SE:text-[1.13rem]">
                  Madoc Santé une plateforme inclusive qui permet aux aidants de
                  participer activement au suivi thérapeutique de leurs proches.
                </div>
              </div>
              <div className="rounded-lg bg-darkslateblue flex flex-row p-[0.94rem] items-start justify-start text-[1.25rem] text-white">
                <div className="relative font-semibold Small_Desktop:text-[1rem]">
                  <a style={{ color:"white" ,textDecoration: "none" }} href={'https://portail.madocsante.fr'} target="_blank">Je m’inscris sur Madoc Santé ✨</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap p-[3.13rem] items-center justify-between large_mobile:gap-[3.13rem] large_mobile:pt-[1.88rem] large_mobile:px-[1.25rem] large_mobile:pb-[3.13rem] large_mobile:box-border Small_Desktop:gap-[3.13rem] Small_Desktop:items-center Small_Desktop:justify-center Small_tablet:pt-[1.25rem] Small_tablet:box-border">
          <div className="relative w-[6.44rem] h-[4.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[6.44rem] h-[4.06rem] object-cover"
              alt=""
              src="/1200pxlogotype-de-la-collectivite-territoriale-de-martinique-1@2x.png"
            />
          </div>
          <div className="relative w-[14.25rem] h-[3.63rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[14.25rem] h-[3.63rem] object-cover"
              alt=""
              src="/prosanteconnect-1@2x.png"
            />
          </div>
          <div className="relative w-[9.64rem] h-[1.91rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logosynapse@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[11.7rem] h-[2.27rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logostellair@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[8.56rem] h-[2.13rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[8.56rem] h-[2.13rem] object-cover"
              alt=""
              src="/logo-withings-1@2x.png"
            />
          </div>
          <div className="relative w-[9.75rem] h-[2.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[9.75rem] h-[2.06rem] object-cover"
              alt=""
              src="/mssante1-2@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-[1rem] large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkorchid flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Gestion et contrôle d’accès des soignants
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Comprendre et m’informer sur la pathologie de mon proche
              </b>
            </div>
            <div className="flex flex-row items-start self-stretch justify-start text-inherit">
              <div className="flex-1 relative leading-[160%] md:text-[1.13rem]">
                <p className="m-0">
                  <span className="font-medium font-metropolis text-darkslateblue">{`Madoc Santé vous permet de participer au suivi thérapeutique de vos proches avec une `}</span>
                  <b className="font-metropolis text-darkorchid">
                    application mobile
                  </b>
                  <span className="font-medium">{` qui concentre l’essentiel du dossier du patient. `}</span>
                </p>
                <p className="m-0 font-medium">&nbsp;</p>
                <p className="m-0">
                  <span className="font-medium">{`Vous pourrez ainsi mieux appréhender leur pathologie et les `}</span>
                  <b className="font-metropolis text-darkorchid">assister</b>
                  <span className="font-medium"> pour :</span>
                </p>
                <ul className="m-0 pl-[1.33rem]">
                  <li className="mb-[false]">
                    <span className="font-medium">{`💊 La prise de médicaments `}</span>
                  </li>
                  <li className="mb-[false]">
                    <span className="font-medium">{`🥗 Leur régime alimentaire `}</span>
                  </li>
                  <li className="mb-[false]">
                    <span className="font-medium">{`🤸‍♂️ Les activités de mobilité`}</span>
                  </li>
                </ul>
                <p className="m-0 font-medium">&nbsp;</p>
                <p className="m-0">
                  <span className="font-medium">{`L’objectif est de participer à une amélioration de leur confort et de leur hygiène de vie pour une `}</span>
                  <b className="font-metropolis text-darkorchid">
                    meilleure continuité des soins
                  </b>
                  <span className="font-medium font-metropolis text-darkslateblue">
                    .
                  </span>
                </p>
              </div>
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_home_page_aidant.svg"
          />
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start text-center text-[2.5rem] text-white Small_Desktop:py-[0rem] Small_Desktop:px-[1.25rem] Small_Desktop:box-border Small_tablet:pt-[3.13rem] Small_tablet:pb-[0.63rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl bg-darkslateblue flex flex-col py-[5.63rem] px-[3.75rem] items-center justify-start gap-[3.13rem] Iphone_SE:pt-[5.63rem] Iphone_SE:box-border large_mobile:py-[3.75rem] large_mobile:px-[1.88rem] large_mobile:box-border Small_Desktop:h-auto Small_Desktop:gap-[3.13rem] Small_Desktop:pt-[3.75rem] Small_Desktop:pb-[3.75rem] Small_Desktop:box-border">
            <div className="flex flex-col items-start self-stretch justify-start">
              <b className="self-stretch relative leading-[120%] Small_Desktop:text-[1.88rem] Small_tablet:text-[1.88rem]">
                Participez au suivi thérapeutique de vos proches en quelques
                clics !
              </b>
            </div>
            <div className="flex flex-row items-start justify-center gap-[3.13rem] text-[1.25rem] Small_tablet:flex-col Small_tablet:gap-[1.25rem] Small_tablet:items-center Small_tablet:justify-center">
              <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start">
                <div className="relative font-semibold Small_Desktop:text-[1.13rem] Small_tablet:text-[1rem]">
                  <a style={{ color:"white" ,textDecoration: "none" }} href={'https://portail.madocsante.fr'} target="_blank">Je m’inscris sur Madoc Santé ✨</a>
                </div>
              </div>
              <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] ">
                <div className="relative font-semibold Small_tablet:text-[1rem]">
                   Application mobile 📱
                </div>
                <div className="rounded bg-chocolate  flex flex-row pt-2 px-1 ml-2 items-start justify-start text-center text-[0.88rem] text-white">
                  <div className="relative font-semibold">
                    Bientôt disponible
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border Small_tablet:pt-[3.13rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl flex flex-col p-[5rem] items-start justify-start gap-[2.44rem] border-[1px] border-solid border-darkorchid lg:pl-[3.75rem] lg:pr-[3.75rem] lg:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border large_mobile:pt-[2.5rem] large_mobile:pb-[2.5rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-col items-start justify-start text-center text-[2.5rem]">
              <b className="self-stretch relative leading-[120%] Small_Desktop:text-[1.88rem]">
                <span>{`Une `}</span>
                <span className="text-chocolate">{`application mobile intuitive `}</span>
                <span>qui facilite votre accompagnement thérapeutique</span>
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] Small_Desktop:gap-[3.13rem] Small_tablet:flex-col">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_dossier_medical.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Dossier médical interactif
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Un dossier patient numérique qui regroupe l’ensemble des
                    antécédents et documents médicaux utiles à votre suivi
                    médical.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] lg:flex-1 Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_ed_therapeutique.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Éducation thérapeutique
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Vidéos, jeux, lecture et autres contenus mis à disposition
                    pour vous aider à mieux comprendre votre pathologie.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_objets_connectes.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Objets connectés
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Ces nouvelles technologiques ont été intégrées pour
                    automatiser la prise de vos constantes en temps réel.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-prosanteconnect@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Authentification sécurisée
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Pro Santé Connect est une solution intégrée sur notre
                    plateforme afin de pouvoir authentifier les soignants
                    inscrits et garantir la sécurité des patients.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-synapse@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Aide à la prescription
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Synapse Plateforme outil d’aide à la prescription pour
                    renforcer la sécurité de la prise en charge médicamenteuse.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-stellair@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Télétransmission
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Stellair Integral conçu pour tous les professionnels de
                    santé libéraux cette solution permet la facturation et la
                    télétransmission en mobilité et en cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/e-planning@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">{`E-planning `}</b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un agenda interactif pour planifier ses rendez-vous patients
                    en cabinet, à domicile ou en visioconférence et organiser
                    ses évènements professionnels.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-teleconsultation@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Téléconsultation
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Madoc Santé a intégré son propre module de téléconsultation.
                    Vos patients pourront donc obtenir un avis médical sur leur
                    état de santé sans se déplacer au cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-dossier-medical1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Suivi médical partagé
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un dossier du patient accessible à toute l’équipe médicale
                    du patient en accès temporaire ou permanent, pour faciliter
                    la prise de décision collégiale des soignants.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Helpers;
