import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Ressources = () => {
  const navigate = useNavigate();
  const onTitreContainerClick = useCallback(() => {
    navigate("/a-propos");
  }, [navigate]);
  const onTitreContainer1Click = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className="rounded-t-none rounded-b-xl z-auto bg-white shadow-[0px_50px_50px_rgba(9,_38,_81,_0.1)] flex flex-col py-[0.94rem] px-[1.25rem] box-border items-start justify-start gap-[0.63rem] max-w-full max-h-full text-left text-[1rem] text-darkslateblue font-metropolis">
      <div
        className="w-[16.81rem] flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
        onClick={onTitreContainerClick}
      >
        <img
          className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
          alt=""
          src="/icon-prosanteconnect5@2x.png"
        />
        <div className="flex flex-col items-start self-stretch justify-center flex-1">
          <div className="flex flex-row items-start self-stretch justify-start">
            <div className="flex-1 relative leading-[155%] font-medium">
              À propos
            </div>
          </div>
        </div>
      </div>
      <div className="w-[16.81rem] flex flex-row items-center justify-start">
        <div className="self-stretch flex-1 flex flex-row items-center justify-start gap-[1.25rem]">
          <img
            className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
            alt=""
            src="/icon-prosanteconnect6@2x.png"
          />
          <div className="flex flex-row items-start justify-start">
            <div className="relative leading-[155%] font-medium">Blog</div>
          </div>
          <div className="rounded bg-chocolate flex flex-row p-[0.31rem] items-start justify-start text-center text-[0.88rem] text-white">
            <div className="relative font-semibold">Bientôt disponible</div>
          </div>
        </div>
      </div>
      <div
        className="w-[16.81rem] flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
        onClick={onTitreContainer1Click}
      >
        <img
          className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
          alt=""
          src="/icon-prosanteconnect7@2x.png"
        />
        <div className="flex flex-col items-start self-stretch justify-center flex-1">
          <div className="flex flex-row items-start self-stretch justify-start">
            <div className="flex-1 relative leading-[155%] font-medium">
              Contact
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ressources;
