import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Patients = () => {
  const navigate = useNavigate();

  const homeNavigation = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const subscriptionNavigation = useCallback(() => {
    navigate("/abonnements");
  }, [navigate]);

  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1rem] text-darkslateblue font-metropolis">
        <div className="self-stretch flex flex-col pt-[0rem] px-[1.88rem] pb-[6.25rem] items-center justify-start text-[1.25rem] lg:pb-[4.38rem] lg:box-border large_mobile:pl-[0.94rem] large_mobile:pr-[0.94rem] large_mobile:box-border Small_tablet:pb-[4.38rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl [background:linear-gradient(180deg,_#ffeada,_rgba(255,_255,_255,_0))] flex flex-col pt-[5rem] px-[0rem] pb-[0rem] items-center justify-start gap-[1.88rem] large_mobile:pt-[3.75rem] large_mobile:box-border">
            <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] sm:hidden Small_Mobile:hidden Iphone_SE:hidden large_mobile:hidden Small_tablet:hidden">
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">⌚️</div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Suivi des constantes
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Coordination des soins
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">🧡</div>
              </div>
            </div>
            <div className="self-stretch flex flex-col py-[0rem] px-[9.38rem] items-center justify-start gap-[1.69rem] text-center text-[4rem] large_mobile:pl-[0rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
              <div className="self-stretch flex flex-row items-start justify-start large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border">
                <b className="flex-1 relative leading-[4.38rem] md:text-[2.81rem] md:leading-[3.44rem] sm:text-[2.19rem] sm:leading-[2.81rem]">
                  <span>{`Faites l’expérience du suivi médical `}</span>
                  <span className="text-chocolate">à distance</span>
                </b>
              </div>
              <div className="self-stretch flex flex-row items-center justify-center text-[1.5rem]">
                <div className="flex-1 relative leading-[2.5rem] font-medium md:text-[1.5rem] sm:text-[1.25rem] sm:leading-[1.88rem] Small_Mobile:text-[1.25rem] Iphone_SE:text-[1.13rem]">
                  Madoc Santé est votre plateforme de télémédecine innovante qui
                  améliore et sécurise votre prise en charge médicale en
                  incluant vos aidants dans votre suivi thérapeutique.
                </div>
              </div>
              <div className="rounded-lg bg-darkslateblue flex flex-row p-[0.94rem] items-start justify-start text-[1.25rem] text-white">
                <div className="relative font-semibold Small_Desktop:text-[1rem]">
                  <a style={{ color:"white" ,textDecoration: "none" }} href={'https://portail.madocsante.fr'} target="_blank">Je m’inscris sur Madoc Santé ✨</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap p-[3.13rem] items-center justify-between large_mobile:gap-[3.13rem] large_mobile:pt-[1.88rem] large_mobile:px-[1.25rem] large_mobile:pb-[3.13rem] large_mobile:box-border Small_Desktop:gap-[3.13rem] Small_Desktop:items-center Small_Desktop:justify-center Small_tablet:pt-[1.25rem] Small_tablet:box-border">
          <div className="relative w-[6.44rem] h-[4.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[6.44rem] h-[4.06rem] object-cover"
              alt=""
              src="/1200pxlogotype-de-la-collectivite-territoriale-de-martinique-1@2x.png"
            />
          </div>
          <div className="relative w-[14.25rem] h-[3.63rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[14.25rem] h-[3.63rem] object-cover"
              alt=""
              src="/prosanteconnect-1@2x.png"
            />
          </div>
          <div className="relative w-[9.64rem] h-[1.91rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logosynapse@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[11.7rem] h-[2.27rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logostellair@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[8.56rem] h-[2.13rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[8.56rem] h-[2.13rem] object-cover"
              alt=""
              src="/logo-withings-1@2x.png"
            />
          </div>
          <div className="relative w-[9.75rem] h-[2.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[9.75rem] h-[2.06rem] object-cover"
              alt=""
              src="/mssante1-2@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Collecte de données médicales
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Suivi de mes constantes médicales en temps réel par objets
                connectés
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] font-medium md:text-[1.13rem]">
                <p className="m-0">{`Suivre l’évolution de son état de santé et transmettre des données médicales à son équipe de soignants devient essentiel au suivi thérapeutique des maladies chroniques. `}</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Aujourd’hui Madoc Santé vous permet de renseigner manuellement
                  vos constantes ou de synchroniser vos objets connectés pour
                  anticiper les risques de décompensations.
                </p>
              </div>
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_home_page_patient.svg"
          />
        </div>
        <div className="self-stretch flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] lg:gap-[1.88rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border large_mobile:flex-col large_mobile:pl-[3.75rem] large_mobile:box-border Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <img
            className="flex-1 relative rounded-xl max-w-full overflow-hidden h-[33.25rem] object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_page_patient_2.svg"
          />
          <div className="flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-start gap-[1.88rem] lg:w-[34.38rem] large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:flex-col Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Centralisation des données de santé
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Gestion autonome de mon dossier médical et mis à jour de mes
                données de santé
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] font-medium md:text-[1.13rem]">
                <p className="m-0">{`Grâce à Madoc Santé, vous pourrez renseigner vos antécédents, consulter vos traitements en cours, télécharger vos ordonnances, prendre des rendez-vous avec votre équipe médicale au même endroit. `}</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Vous devenez acteur de votre santé et pouvez gérer de manière
                  autonome votre dossier médical chez vous et n’importe ailleurs
                  depuis votre smartphone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Gestion et contrôle d’accès des soignants
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Contrôle d’accès permanent ou temporaire à mon dossier médical
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] font-medium md:text-[1.13rem]">
                <p className="m-0">{`Chaque donnée médicale est une source d’information privée et qui doit être contrôlée dans sa diffusion. `}</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  C’est pourquoi chez Madoc Santé, l’accès au dossier médical du
                  patient sera autorisé uniquement par le patient (ou l’aidant)
                  de manière temporaire ou permanente, suite à une demande
                  préalablement du soignant.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  En cas d’urgence vitale, un bris de glace pourra être accordé
                  pour consulter le dossier du patient via le DMP (Dossier
                  Médical Partagé).
                </p>
              </div>
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_page_patient_3.svg"
          />
        </div>
        <div className="self-stretch flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] lg:gap-[1.88rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border large_mobile:flex-col large_mobile:pl-[3.75rem] large_mobile:box-border Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_page_patient_4.svg"
          />
          <div className="flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-start gap-[1.88rem] lg:w-[34.38rem] large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:flex-col Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Autonomie thérapeutique
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Documentation et ressources disponibles pour comprendre ma
                pathologie
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] font-medium md:text-[1.13rem]">
                <p className="m-0">{`Pour que chaque patient puisse mieux appréhender sa pathologie, Madoc Santé propose un ensemble de contenus thérapeutique sous forme de vidéos, de jeux, de lecture et d’audio. `}</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  L’information doit être à la portée de tous ; c’est un outil
                  indispensable pour les patients qui ont la volonté d’acquérir
                  une autonomie thérapeutique si le médecin a préalablement
                  évalué sa capacité à le faire.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start text-center text-[2.5rem] text-white Small_Desktop:py-[0rem] Small_Desktop:px-[1.25rem] Small_Desktop:box-border Small_tablet:pt-[3.13rem] Small_tablet:pb-[0.63rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl bg-darkslateblue flex flex-col py-[5.63rem] px-[3.75rem] items-center justify-start gap-[3.13rem] Iphone_SE:pt-[5.63rem] Iphone_SE:box-border large_mobile:py-[3.75rem] large_mobile:px-[1.88rem] large_mobile:box-border Small_Desktop:h-auto Small_Desktop:gap-[3.13rem] Small_Desktop:pt-[3.75rem] Small_Desktop:pb-[3.75rem] Small_Desktop:box-border">
            <div className="flex flex-col items-start self-stretch justify-start">
              <b className="self-stretch relative leading-[120%] Small_Desktop:text-[1.88rem] Small_tablet:text-[1.88rem]">{`Bénéficiez de tous les avantages d’un suivi médical coordonné et personnalisé à distance `}</b>
            </div>
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-[1.25rem]">
              <div className="relative font-semibold Small_Desktop:text-[1.13rem] Small_tablet:text-[1rem]">
                <a style={{ color:"white" ,textDecoration: "none" }} href={'https://portail.madocsante.fr'} target="_blank">Je m’inscris sur Madoc Santé ✨</a>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start text-[1.25rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border Small_tablet:pt-[3.13rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl flex flex-col p-[5rem] items-start justify-start gap-[2.44rem] border-[1px] border-solid border-darkorchid lg:pl-[3.75rem] lg:pr-[3.75rem] lg:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border large_mobile:pt-[2.5rem] large_mobile:pb-[2.5rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-col items-start justify-start text-center text-[2.5rem]">
              <b className="self-stretch relative leading-[120%] Small_Desktop:text-[1.88rem]">
                <p className="m-0">
                  <span className="text-darkslateblue">{`Des `}</span>
                  <span className="text-chocolate">
                    fonctionnalités sur-mesure
                  </span>
                  <span>{` qui `}</span>
                </p>
                <p className="m-0">{`vous rendent plus autonome `}</p>
                <p className="m-0">et personnalisent votre suivi médical</p>
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] Small_Desktop:gap-[3.13rem] Small_tablet:flex-col">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_e_planning.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Prise de rendez-vous
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Un agenda interactif pour planifier ses rendez-vous patients
                    en cabinet, à domicile ou en visioconférence et organiser
                    ses évènements professionnels.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_teleconsultation.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[0.88rem]">
                    Téléconsultation
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Madoc Santé a intégré son propre module de téléconsultation
                    que vous puissiez obtenir un avis médical sur votre état de
                    santé sans vous déplacer au cabinet.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_dossier_medical.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Dossier médical interactif
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Un dossier patient numérique qui regroupe l’ensemble des
                    antécédents et documents médicaux utiles à votre suivi
                    médical.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-prosanteconnect@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Authentification sécurisée
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Pro Santé Connect est une solution intégrée sur notre
                    plateforme afin de pouvoir authentifier les soignants
                    inscrits et garantir la sécurité des patients.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-synapse@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Aide à la prescription
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Synapse Plateforme outil d’aide à la prescription pour
                    renforcer la sécurité de la prise en charge médicamenteuse.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-stellair@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Télétransmission
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Stellair Integral conçu pour tous les professionnels de
                    santé libéraux cette solution permet la facturation et la
                    télétransmission en mobilité et en cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/e-planning@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">{`E-planning `}</b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un agenda interactif pour planifier ses rendez-vous patients
                    en cabinet, à domicile ou en visioconférence et organiser
                    ses évènements professionnels.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-teleconsultation1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Téléconsultation
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Madoc Santé a intégré son propre module de téléconsultation.
                    Vos patients pourront donc obtenir un avis médical sur leur
                    état de santé sans se déplacer au cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-dossier-medical1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Suivi médical partagé
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un dossier du patient accessible à toute l’équipe médicale
                    du patient en accès temporaire ou permanent, pour faciliter
                    la prise de décision collégiale des soignants.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] Small_Desktop:gap-[3.13rem] Small_tablet:flex-col">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_objets_connectes.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Objets connectés
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Ces nouvelles technologiques ont été intégrées pour
                    automatiser la prise de vos constantes en temps réel.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] lg:flex-1 Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_messagerie.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Notifications
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Profitez de notre espace de notification pour informer votre
                    équipe médicale sur votre état de santé.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] lg:flex-1 Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_ed_therapeutique.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Éducation thérapeutique
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Vidéos, jeux, lecture et autres contenus mis à disposition
                    pour vous aider à mieux comprendre votre pathologie.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Patients;
