import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

const Professionals = () => {
  const navigate = useNavigate();

  const homeNavigation = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const subscriptionNavigation = useCallback(() => {
    navigate("/abonnements");
  }, [navigate]);

  return (
    <>
      <div className="[text-decoration:none] relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        <div className="self-stretch flex flex-col pt-[0rem] px-[1.88rem] pb-[6.25rem] items-center justify-start lg:pb-[4.38rem] lg:box-border large_mobile:pl-[0.94rem] large_mobile:pr-[0.94rem] large_mobile:box-border Small_tablet:pb-[4.38rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl [background:linear-gradient(180deg,_#e7f4fa,_rgba(255,_255,_255,_0))] flex flex-col pt-[5rem] px-[0rem] pb-[0rem] items-center justify-start gap-[1.88rem] large_mobile:pt-[3.75rem] large_mobile:box-border">
            <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] sm:hidden Small_Mobile:hidden Iphone_SE:hidden large_mobile:hidden Small_tablet:hidden">
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">⌚️</div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Suivi des constantes
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Coordination des soins
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">🧡</div>
              </div>
            </div>
            <div className="self-stretch flex flex-col py-[0rem] px-[9.38rem] items-center justify-start gap-[1.69rem] text-center text-[4rem] large_mobile:pl-[0rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
              <div className="self-stretch flex flex-row items-start justify-start large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border">
                <b className="flex-1 relative leading-[4.38rem] md:text-[2.81rem] md:leading-[3.44rem] sm:text-[2.19rem] sm:leading-[2.81rem]">
                  <span>{`Découvrez notre parcours de soins `}</span>
                  <span className="text-darkcyan">connecté</span>
                  <span>{` `}</span>
                </b>
              </div>
              <div className="self-stretch flex flex-row items-center justify-center text-[1.5rem]">
                <div className="flex-1 relative leading-[2.5rem] font-medium md:text-[1.5rem] sm:text-[1.25rem] sm:leading-[1.88rem] Small_Mobile:text-[1.25rem] Iphone_SE:text-[1.13rem]">
                  Madoc Santé assiste les professionnels de santé pour que
                  chaque patient bénéficie d’une gestion coordonnée de son
                  dossier médical et d’un suivi médical personnalisé à distance.
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center gap-[1.88rem] text-[1.25rem] text-white large_mobile:flex-col large_mobile:gap-[0.63rem] large_mobile:items-center large_mobile:justify-center">
                <div className="rounded-lg bg-darkslateblue flex flex-row p-[0.94rem] items-start justify-start">
                  <div  className="relative font-semibold Small_Desktop:text-[1rem]">
                   <a style={{ color:"white" ,textDecoration: "none" }} href={'https://pro.madocsante.fr'} target="_blank" >Essayer Madoc Santé ✨</a> 
                  </div>
                </div>
                {/* <div className="rounded-lg flex flex-row p-[0.94rem] items-start justify-start text-chocolate border-[2px] border-solid border-antiquewhite">
                  <div className="relative font-semibold Small_Desktop:text-[1rem]">
                    Changer d’abonnement 👈
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap p-[3.13rem] items-center justify-between large_mobile:gap-[3.13rem] large_mobile:pt-[1.88rem] large_mobile:px-[1.25rem] large_mobile:pb-[3.13rem] large_mobile:box-border Small_Desktop:gap-[3.13rem] Small_Desktop:items-center Small_Desktop:justify-center Small_tablet:pt-[1.25rem] Small_tablet:box-border">
          <div className="relative w-[6.44rem] h-[4.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[6.44rem] h-[4.06rem] object-cover"
              alt=""
              src="/1200pxlogotype-de-la-collectivite-territoriale-de-martinique-1@2x.png"
            />
          </div>
          <div className="relative w-[14.25rem] h-[3.63rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[14.25rem] h-[3.63rem] object-cover"
              alt=""
              src="/prosanteconnect-1@2x.png"
            />
          </div>
          <div className="relative w-[9.64rem] h-[1.91rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logosynapse@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[11.7rem] h-[2.27rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logostellair@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[8.56rem] h-[2.13rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[8.56rem] h-[2.13rem] object-cover"
              alt=""
              src="/logo-withings-1@2x.png"
            />
          </div>
          <div className="relative w-[9.75rem] h-[2.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[9.75rem] h-[2.06rem] object-cover"
              alt=""
              src="/mssante1-2@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-[1rem] lg:gap-[1.88rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border large_mobile:flex-col large_mobile:pl-[3.75rem] large_mobile:box-border Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_page_pro_1.svg"
          />
          <div className="flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-start gap-[1.88rem] lg:w-[34.38rem] large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:flex-col Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Collecte des données médicales
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Mise en valeur des données de santé des patients
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] font-medium md:text-[1.13rem]">
                <p className="m-0">{`Madoc Santé vous permet en quelques clics de consulter le dossier de chaque patient, le compléter si nécessaire et ajuster son suivi thérapeutique en temps réel. `}</p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Vous pourrez observer les constantes du patient, suivre ses
                  traitements et accéder à ses antécédents.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-[1rem] large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">
                Coordination des soins médicaux
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Sécurisation des échanges entre professionnels
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] md:text-[1.13rem]">
                <p className="m-0 font-medium">
                  Avec Madoc Santé, vous pourrez utiliser un espace dédié aux
                  échanges en équipe sur vos dossiers patients pour que toutes
                  les données de santé soient sécurisées et centralisées au même
                  endroit.
                </p>
                <p className="m-0 font-medium">&nbsp;</p>
                <p className="m-0 font-medium">
                  Vous pourrez créer des notifications et des notes de suivi
                  partagées et décidér à qui elles seront destinées afin
                  d’assurer la confidentialité de vos échanges.
                </p>
                <p className="m-0">
                  <span className="font-medium">{`Nous vous proposons aussi un lien d’accès direct à `}</span>
                  <b className="font-metropolis">MS Santé</b>
                  <span className="font-medium font-metropolis">.</span>
                </p>
              </div>
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_page_pro_2.svg"
          />
        </div>
        <div className="self-stretch flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-[1rem] lg:gap-[1.88rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border large_mobile:flex-col large_mobile:pl-[3.75rem] large_mobile:box-border Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img_page_pro_3.svg"
          />
          <div className="flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-start gap-[1.88rem] lg:w-[34.38rem] large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:flex-col Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] items-start justify-start text-center text-white">
              <div className="relative font-semibold">{`Prescriptions & transmissions`}</div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[2rem]">
              <b className="flex-1 relative leading-[2.5rem] md:text-[1.75rem]">
                Prescription et facturation des patients au même endroit
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem]">
              <div className="flex-1 relative leading-[160%] md:text-[1.13rem]">
                <p className="m-0">
                  <span className="font-medium font-metropolis">{`Un outil complet qui vous permettra de facturer vos patients avec le module `}</span>
                  <b className="font-metropolis">Stellair Intégral</b>
                  <span className="font-medium">
                    {" "}
                    et son boitier 4G de manière simple et rapide.
                  </span>
                </p>
                <p className="m-0 font-medium">&nbsp;</p>
                <p className="m-0">
                  <span className="font-medium">{`Profitez également des avantages de `}</span>
                  <b className="font-metropolis">Synapse Medecine</b>
                  <span className="font-medium">
                    , notre partenaire d’aide à la prescription pour gagner du
                    temps dans la réalisation de vos ordonnances et prévenir les
                    incompatibilités médicamenteuses.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start text-center text-[2.5rem] text-white Small_Desktop:py-[0rem] Small_Desktop:px-[1.25rem] Small_Desktop:box-border Small_tablet:pt-[3.13rem] Small_tablet:pb-[0.63rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl bg-darkslateblue flex flex-col py-[5.63rem] px-[3.75rem] items-center justify-start gap-[3.13rem] Iphone_SE:pt-[5.63rem] Iphone_SE:box-border large_mobile:py-[3.75rem] large_mobile:px-[1.88rem] large_mobile:box-border Small_Desktop:h-auto Small_Desktop:gap-[3.13rem] Small_Desktop:pt-[3.75rem] Small_Desktop:pb-[3.75rem] Small_Desktop:box-border">
            <div className="flex flex-col items-start self-stretch justify-start">
              <b className="self-stretch relative leading-[120%] Small_Desktop:text-[1.88rem] Small_tablet:text-[1.88rem]">
                Coordonnez la prise en charge de vos patients dès à présent !
              </b>
            </div>
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-[1.25rem]">
              <div className="relative font-semibold Small_Desktop:text-[1.13rem] Small_tablet:text-[1rem]">
              <a style={{ color:"white" ,textDecoration: "none" }} href={'https://pro.madocsante.fr'} target="_blank" >Essayer Madoc Santé ✨</a> 
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[5rem] items-center justify-start lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border Small_tablet:pt-[3.13rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl flex flex-col p-[5rem] items-start justify-start gap-[2.44rem] border-[1px] border-solid border-darkorchid lg:pl-[3.75rem] lg:pr-[3.75rem] lg:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border large_mobile:pt-[2.5rem] large_mobile:pb-[2.5rem] large_mobile:box-border Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-col items-start justify-start text-center text-[2.5rem]">
              <b className="self-stretch relative leading-[120%] Small_Desktop:text-[1.88rem]">
                <p className="m-0">
                  <span className="text-darkslateblue">{`Des `}</span>
                  <span className="text-chocolate">
                    fonctionnalités sur-mesure
                  </span>
                  <span>{` qui `}</span>
                </p>
                <p className="m-0">{`améliorent votre prise de décision `}</p>
                <p className="m-0">et facilitent votre suivi médical</p>
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] Small_Desktop:gap-[3.13rem] Small_tablet:flex-col">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-prosanteconnect@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Authentification sécurisée
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Pro Santé Connect est une solution intégrée sur notre
                    plateforme afin de pouvoir authentifier les soignants
                    inscrits et garantir la sécurité des patients.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_synapse.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Aide à la prescription
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Synapse Plateforme outil d’aide à la prescription pour
                    renforcer la sécurité de la prise en charge médicamenteuse.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_stellair.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Télétransmission
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Stellair Integral conçu pour tous les professionnels de
                    santé libéraux cette solution permet la facturation et la
                    télétransmission en mobilité et en cabinet.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] Small_Desktop:gap-[3.13rem] Small_tablet:flex-col">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_e_planning.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">{`E-planning `}</b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Un agenda interactif pour planifier ses rendez-vous patients
                    en cabinet, à domicile ou en visioconférence et organiser
                    ses évènements professionnels.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_teleconsultation.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[0.88rem]">
                    Téléconsultation
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Madoc Santé a intégré son propre module de téléconsultation.
                    Vos patients pourront donc obtenir un avis médical sur leur
                    état de santé sans se déplacer au cabinet.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_dossier_medical.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Suivi médical partagé
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Un dossier du patient accessible à toute l’équipe médicale
                    du patient en accès temporaire ou permanent, pour faciliter
                    la prise de décision collégiale des soignants.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-prosanteconnect1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Authentification sécurisée
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Pro Santé Connect est une solution intégrée sur notre
                    plateforme afin de pouvoir authentifier les soignants
                    inscrits et garantir la sécurité des patients.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-synapse1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Aide à la prescription
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Synapse Plateforme outil d’aide à la prescription pour
                    renforcer la sécurité de la prise en charge médicamenteuse.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-stellair1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Télétransmission
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Stellair Integral conçu pour tous les professionnels de
                    santé libéraux cette solution permet la facturation et la
                    télétransmission en mobilité et en cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/e-planning@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">{`E-planning `}</b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un agenda interactif pour planifier ses rendez-vous patients
                    en cabinet, à domicile ou en visioconférence et organiser
                    ses évènements professionnels.
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-start justify-start gap-[5.5rem]">
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-teleconsultation1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Téléconsultation
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Madoc Santé a intégré son propre module de téléconsultation.
                    Vos patients pourront donc obtenir un avis médical sur leur
                    état de santé sans se déplacer au cabinet.
                  </div>
                </div>
              </div>
              <div className="w-[20.88rem] flex flex-col items-start justify-start gap-[1.25rem]">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-dossier-medical1@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    Suivi médical partagé
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Un dossier du patient accessible à toute l’équipe médicale
                    du patient en accès temporaire ou permanent, pour faciliter
                    la prise de décision collégiale des soignants.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] Small_Desktop:gap-[3.13rem] Small_tablet:flex-col">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] lg:flex-1 Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_messagerie.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Messagerie sécurisée
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Un espace d’échange sécurisé pour tous les soignants afin de
                    garantir la confidentialité des données de santé des
                    patients et améliorer la traçabilité.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon_remplacants.svg"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    Espace remplaçants
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Notre solution donne également accès aux professionnels
                    remplaçants pour assurer la continuité de soins.
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] lg:flex-1 Small_tablet:flex-[unset] Small_tablet:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-dmp@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem] Small_Desktop:text-[1rem]">
                    DMP Compatible
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium Small_Desktop:text-[0.88rem]">
                    Le carnet de santé numérique permettra aux soignants
                    d’importer ou exporter des informations médicales
                    essentielles à la prise en charge du patient.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col py-[3.75rem] px-[7.5rem] items-center justify-start text-[1.5rem] text-darkcyan lg:pl-[1.25rem] lg:pr-[1.25rem] lg:box-border large_mobile:pt-[1.88rem] large_mobile:pb-[1.88rem] large_mobile:box-border">
          <div className="self-stretch rounded-xl bg-aliceblue flex flex-col py-[3.75rem] px-[7.5rem] items-center justify-start gap-[4.13rem] sm:gap-[2.5rem] Small_tablet:pl-[1.88rem] Small_tablet:pr-[1.88rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-row items-center justify-center text-center text-[2.5rem] text-darkslateblue">
              <b className="flex-1 relative leading-[120%] sm:text-[1.88rem] Small_Desktop:text-[1.88rem]">
                <span>{`Nos abonnements `}</span>
                <span className="text-chocolate">mensuels</span>
              </b>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_forfait_basic.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1.25rem]">
                    Basic : 0€
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.25rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] Small_Desktop:text-[1rem] Small_tablet:text-[1rem]">
                    <span className="font-medium">{`Un environnement de travail simple pour `}</span>
                    <b>découvrir la plateforme</b>
                    <span className="font-medium">
                      {" "}
                      et consulter le dossier médical de vos patients en accès
                      temporaire.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_forfait_communication.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1.25rem]">
                    Communication : 64,99€ / mois
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] Small_Desktop:text-[1rem] Small_tablet:text-[1rem]">
                    <span className="font-medium">{`Améliorer la `}</span>
                    <b>coordination des soins</b>
                    <span className="font-medium">{` des équipes médicales avec un `}</span>
                    <b>outil de traçabilité</b>
                    <span className="font-medium">{` adapté et sécurisé afin de `}</span>
                    <b>limiter la perte de données</b>
                    <span className="font-medium">.</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_forfait_facturation.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1.25rem]">
                    Facturation : 119,99€ / mois
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.25rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] Small_Desktop:text-[1rem] Small_tablet:text-[1rem]">
                    <span className="font-medium">{`Un module de `}</span>
                    <b>télétransmission et de facturation des soins,</b>
                    <span className="font-medium">{` accompagné d’un `}</span>
                    <b>lecteur de cartes vitales FSE 3G/4G</b>
                    <span className="font-medium">
                      {" "}
                      utilisable en mobilité ou en cabinet.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon_forfait_complet.svg"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_Desktop:text-[1.25rem] Small_tablet:text-[1.25rem]">
                    Complet : 139,99€ / mois
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.25rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] Small_Desktop:text-[1rem] Small_tablet:text-[1rem]">
                    <span className="font-medium">{`Une solution `}</span>
                    <b>d’aide à la prescription</b>
                    <span className="font-medium">{` intégrant une `}</span>
                    <b>
                      fonctionnalité de recherche d’interaction médicamenteuse
                    </b>
                    <span className="font-medium">
                      {" "}
                      pour sécuriser la prise de médicament.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-center justify-start gap-[0.63rem] text-center text-[1.25rem] text-white">
              <Link
                style={{ color:"white" ,textDecoration: "none" }}
                to="/abonnements"
                className="relative font-semibold Small_Desktop:text-[1rem]"
              >{`Tous les abonnements `}</Link>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/vuesaxlineararrowright3.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Professionals;
