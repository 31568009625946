import React from "react";
import FreePack from "./FreePack";
import CommunicationPack from "./CommunicationPack";
import BillingPack from "./BillingPack";
import CompletePack from "./CompletePack";


const PricePack = () => {
  return (
    <section className="pt-20 flex justify-center items-center flex-wrap gap-4">
      <FreePack />
      <CommunicationPack />
      <BillingPack />
      <CompletePack />
    </section>
  );
};

export default PricePack;
