import { Link, useNavigate } from "react-router-dom";
import Pdf from "../assets/pdf/Mentions légales et politique de confidentialité.pdf";
import { useCallback, useState } from "react";
import Ressources from "./Ressources";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";

const NewHeader = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuToggle = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const [isMenu1Open, setMenu1Open] = useState(false);
  const [isRessourcesOpen, setRessourcesOpen] = useState(false);
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false);

  const openMenu1 = useCallback(() => {
    setMenu1Open(!isMenu1Open);
    if (isRessourcesOpen) {
      setRessourcesOpen(false);
    }
  }, [isMenu1Open, isRessourcesOpen]);

  const closeMenu1 = useCallback(() => {
    setMenu1Open(false);
  }, []);

  const openRessources = useCallback(() => {
    setRessourcesOpen(!isRessourcesOpen);
    console.log('isMenu1Open',isMenu1Open);
    if (isMenu1Open) {
      setMenu1Open(false);
    }
  }, [isRessourcesOpen, isMenu1Open]);

  const closeRessources = useCallback(() => {
    setRessourcesOpen(false);
  }, []);

  const openMenuMobile = useCallback(() => {
    setMenuMobileOpen(!isMenuMobileOpen);
  }, [isMenuMobileOpen]);

  const closeMenuMobile = useCallback(() => {
    setMenuMobileOpen(false);
  }, []);

  const navigate = useNavigate();

  const homeNavigation = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const subscriptionNavigation = useCallback(() => {
    if(isMenu1Open) {
      setMenu1Open(false);
    }
    if(isRessourcesOpen) {
      setRessourcesOpen(false);
    }
    navigate("/abonnements");
  }, [navigate]);

    return (
        <>
            <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        <nav className="m-0 self-stretch bg-white flex flex-row py-[1.25rem] px-[3.13rem] items-center justify-between text-left text-[2rem] text-chocolate font-proxima-nova md:flex md:pl-[1.88rem] md:pr-[1.88rem] md:box-border sm:pl-[1.25rem] sm:pr-[1.25rem] sm:box-border">
          <div className="flex flex-row items-center justify-center">
            <b
              className="relative Small_Mobile:text-[1.56rem] cursor-pointer"
              onClick={homeNavigation}
            >
              madoc sante
            </b>
          </div>
          <div className="flex flex-row items-center justify-start text-center text-[1rem] text-darkslateblue font-metropolis md:flex">
            <div className="w-[40.44rem] flex flex-row items-center justify-between md:hidden">
              <div className="w-[25.56rem] flex flex-row items-center justify-between">
                <div
                  className="w-[6.38rem] flex flex-row items-center justify-between cursor-pointer"
                  onClick={openMenu1}
                >
                  <div className="flex flex-row items-start justify-start">
                    <div className="relative font-medium">Solutions</div>
                  </div>
                  <img
                    className="relative w-[1rem] h-[1rem]"
                    alt=""
                    src="/icon-button.svg"
                  />
                  <div className="relative">
                    {isMenu1Open && (
                      <div className="absolute z-10 items-center top-4 -left-32">
                        <Menu onClose={closeRessources} onOutsideClick={closeMenu1} />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  onClick={subscriptionNavigation}
                  className="flex flex-row items-start justify-start cursor-pointer"
                >
                  <div className="relative font-medium">Abonnements Pro</div>
                </div>
                <div
                  className="flex flex-row items-start justify-start gap-[0.63rem] cursor-pointer"
                  onClick={openRessources}
                >
                  <div className="relative font-medium">Ressources</div>
                  <img
                    className="w-[1rem] h-[1rem]"
                    alt=""
                    src="/icon-button.svg"
                  />
                  <div className="relative">
                    {isRessourcesOpen && (
                      <div className="absolute z-10 items-center top-4 -left-32">
                        <Ressources onClose={closeRessources} />
                      </div>
                    )}
                  </div>
                  
                </div>
              </div>
              <div className="rounded-lg bg-antiquewhite flex flex-row py-[0.94rem] px-[0.75rem] items-start justify-start text-chocolate">
                <div className="relative font-semibold">
                  <a 
                  style={{ textDecoration: "none" }}
                  href="contact"
                  className="text-chocolate"
                  >Demander une Démo</a>
                </div>
              </div>
            </div>
            <div
              className="w-[4.75rem] hidden flex-row items-center justify-between cursor-pointer md:flex"
              onClick={openMenuMobile}
            >
              <div className="flex flex-row items-start justify-start">
                <div className="relative font-medium">Menu</div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/icon_menu.svg"
              />
               <div className="relative">
                    {isMenuMobileOpen && (
                      <div className="absolute z-20 items-center top-8 -left-72">
                        <MenuMobile onClose={closeMenuMobile} />
                      </div>
                    )}
                  </div>
            </div>
          </div>
        </nav>
      </div>
      {/* {isMenu1Open && (
        <PortalPopup placement="Top center" onOutsideClick={closeMenu1}>
          <Menu onClose={closeMenu1} />
        </PortalPopup>
      )} */}
      {/* {isRessourcesOpen && (
        <PortalPopup placement="Top" onOutsideClick={closeRessources}>
          <Ressources onClose={closeRessources} />
        </PortalPopup>
      )} */}
      {/* {isMenuMobileOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeMenuMobile}
        >
          <MenuMobile onClose = {closeMenuMobile} />
        </PortalPopup>
      )} */}

        
        </>
    );
};

export default NewHeader;