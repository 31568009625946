import { Link } from "react-router-dom";
import Pdf from "../assets/pdf/Mentions légales et politique de confidentialité.pdf";

const Footer = () => {
  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        <div className="self-stretch bg-white flex flex-col pt-[3.13rem] px-[3.13rem] pb-[1.25rem] items-start justify-center gap-[3.44rem] Small_tablet:pl-[1.25rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl bg-darkslateblue flex flex-col p-[3.13rem] items-start justify-start gap-[5rem] text-white lg:self-stretch lg:w-auto lg:gap-[6.25rem] sm:p-[1.88rem] sm:box-border Small_Mobile:pl-[1.88rem] Small_Mobile:pr-[1.88rem] Small_Mobile:box-border large_mobile:flex-col Small_Desktop:flex-col Small_Desktop:gap-[3.13rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.44rem]">
              <div className="self-stretch flex flex-col items-start justify-start Iphone_SE:w-auto Iphone_SE:[align-self:unset] large_mobile:self-stretch large_mobile:w-auto large_mobile:flex-1 large_mobile:flex-col">
                <div className="flex flex-row items-center justify-start gap-[0.63rem] large_mobile:hidden">
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative leading-[150%] font-medium">
                      ⌚️
                    </div>
                  </div>
                  <img
                    className="relative w-[1.5rem] h-[1.5rem]"
                    alt=""
                    src="/vuesaxlineararrowright3.svg"
                  />
                  <div className="self-stretch flex flex-row items-center justify-start text-[1rem]">
                    <div className="relative leading-[150%] font-medium">
                      Suivi des constantes
                    </div>
                  </div>
                  <img
                    className="relative w-[1.5rem] h-[1.5rem]"
                    alt=""
                    src="/vuesaxlineararrowright3.svg"
                  />
                  <div className="self-stretch flex flex-row items-center justify-start text-[1rem]">
                    <div className="relative leading-[150%] font-medium">
                      Coordination des soins
                    </div>
                  </div>
                  <img
                    className="relative w-[1.5rem] h-[1.5rem]"
                    alt=""
                    src="/vuesaxlineararrowright3.svg"
                  />
                  <div className="flex flex-row items-center justify-start text-darkslateblue">
                    <div className="relative leading-[150%] font-medium">
                      🧡
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start text-[2.5rem]">
                <b className="flex-1 relative leading-[3.13rem] large_mobile:text-[1.88rem] large_mobile:leading-[2.19rem]">
                  <span>{`La médecine `}</span>
                  <span className="text-darkcyan">{`connectée `}</span>
                  <span>{`pour tous les parcours de soins `}</span>
                </b>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[3.44rem] large_mobile:flex-col Small_Desktop:flex-col Small_Desktop:gap-[3.44rem]">
              <div className="w-[7.88rem] flex flex-col items-start justify-start gap-[1.31rem]">
                <div className="flex flex-row items-center justify-center">
                  <b className="relative leading-[150%]">Madoc santé</b>
                </div>
                <div className="flex flex-col items-start justify-start gap-[1.06rem] text-[1.13rem]">
                  <div className="flex flex-row items-center justify-center">
                    <Link
                      to="/a-propos"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <div className="relative leading-[150%] font-medium cursor-pointer">
                        À propos
                      </div>
                    </Link>
                  </div>
                  <div className="flex flex-row items-center justify-center">
                    <div className="relative leading-[150%] font-medium">
                      Blog
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center cursor-pointer">
                    <Link
                      to="/contact"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <div className="relative leading-[150%] font-medium">
                        Contact
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[1.25rem]">
                <div className="flex flex-row items-center justify-center">
                  <b className="relative leading-[150%]">Solutions</b>
                </div>
                <div className="flex flex-col items-start justify-start gap-[1.06rem] text-[1.13rem]">
                  <div className="flex flex-row items-center self-stretch justify-start">
                    <Link
                      to="/professionnels-de-sante"
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <div className="relative leading-[150%] font-medium">
                        Professionnels de santé
                      </div>
                    </Link>
                  </div>
                  <div className="flex flex-row items-center self-stretch justify-start">
                    <div className="relative leading-[150%] font-medium">
                      <Link
                        to="/patients"
                        style={{ textDecoration: "none" }}
                        className="text-white"
                      >
                        <span className="cursor-pointer">Patient</span>{" "}
                      </Link>
                      &{" "}
                      <Link
                        to="/aidants"
                        style={{ textDecoration: "none" }}
                        className="text-white"
                      >
                        <span className="cursor-pointer">Aidants</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row py-[0rem] px-[3.13rem] items-center justify-between text-[1.13rem] md:pl-[0.63rem] md:pr-[0.63rem] md:box-border large_mobile:flex-col Small_tablet:flex-col Small_tablet:gap-[1.88rem]">
            <div className="flex flex-row items-center justify-start gap-[2.69rem] large_mobile:flex-col large_mobile:gap-[1.56rem]">
              <div className="flex flex-row items-center justify-center">
                <b className="relative leading-[1.56rem] flex items-center w-[18.38rem] shrink-0 large_mobile:text-center">
                  <span className="[line-break:anywhere] w-full">
                    <p className="m-0">{`Besoin d’une démo ? `}</p>
                    <p className="m-0">Des questions ? Des remarques ?</p>
                  </span>
                </b>
              </div>
              <Link
                to="/contact"
                style={{ textDecoration: "none" }}
                className="text-white"
              >
                <div className="rounded-lg bg-chocolate flex flex-row py-[0.94rem] px-[0.75rem] items-start justify-start text-center text-[1rem] text-white">
                  <div className="relative font-semibold">Contactez-nous</div>
                </div>
              </Link>
            </div>
            <div className="w-[16.19rem] flex flex-row items-center justify-between text-center text-[1rem] text-chocolate Iphone_SE:gap-[0rem]">
              <div className="rounded-lg bg-antiquewhite flex flex-row py-[0.94rem] px-[0.75rem] items-start justify-start">
                <div className="relative font-semibold">Retrouvez-nous sur</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[0.94rem]">
                <div className="flex flex-row items-start justify-start">
                  <a
                    href="https://www.instagram.com/madoc_sante/"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="relative w-[1.56rem] h-[1.57rem]"
                      alt=""
                      src="/group.svg"
                    />
                  </a>
                </div>
                <div className="flex flex-row items-start justify-start">
                  <a
                    href="https://www.linkedin.com/company/madoc-sant%C3%A9/?viewAsMember=true"
                    target={"_blank"}
                    to="/"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      className="relative w-[1.56rem] h-[1.56rem]"
                      alt=""
                      src="/vector.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-3xs bg-aliceblue flex flex-row py-[1.88rem] px-[3.13rem] items-center md:items-start md:justify-start justify-between text-[1rem] Small_Mobile:gap-[11.38rem] Small_Mobile:pl-[3.13rem] Small_Mobile:box-border large_mobile:flex-col Small_Desktop:flex-col Small_Desktop:gap-[1.88rem] Small_Desktop:justify-center">
            <div className="flex flex-row items-start justify-start">
              <div className="relative leading-[150%] font-medium large_mobile:text-center">
                (c) Madoc Santé 2022 - Propulsé par Novwell
              </div>
            </div>
            <div className="flex flex-row md:flex-col items-start justify-start gap-[2.38rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-center large_mobile:justify-center">
              <a className='text-darkslateblue' href={Pdf} target="_blank">
                <div className="relative md:items-start leading-[150%] font-medium flex items-center w-[8.13rem] h-[0.69rem] shrink-0">{`Mentions légales `}</div>
              </a>
              
              <a className='text-darkslateblue' href={Pdf} target="_blank">
                <div className="relative leading-[150%] font-medium flex items-center w-[12.94rem] h-[0.69rem] shrink-0">
                  Politique de confidentialité
                </div>
              </a>
              <a className='text-darkslateblue' href={Pdf} target="_blank">
                <div className="relative leading-[150%] font-medium flex items-center w-[9.81rem] h-[0.69rem] shrink-0">
                  Politique de cookies
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
