import PricePack from "../components/PricePack";

const Subscriptions = () => {
  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        {/* Div total de text*/}
        <div className="items-center justify-center py-8 text-center px-44 lg:px-12">
            <h3 className="text-3xl text-darkslateblue font-bold _lg:text-[40px]">
              Je choisis la formule qui correspond à ma méthode de travail et à
              mes besoins.
            </h3>
            <p className="pb-8 text-xl text-darkslateblue">
              Une offre de service modulable pour accompagner tous les parcours
              de soin. Avec Madoc Santé, faites le choix de la flexibilité et
              choisissez la formule qui correspond au mode de prise en charge de
              vos patients.
            </p>
            {/* Button Je souscris à Madoc Santé ✨*/}
            <a 
            className="p-4 rounded-lg sm:text-sm bg-darkslateblue"
            style={{ color:"white" ,textDecoration: "none" }} 
            href={'https://pro.madocsante.fr'} 
            target="_blank">Je souscris à Madoc Santé ✨
            </a>
        </div>
        {/* Componnent Price Packs*/}
        <div className="pb-3">
          <PricePack />
        </div>
        
      </div>
    </>
  );
};

export default Subscriptions;
