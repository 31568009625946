import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Newsletters from "../components/Newsletters";

const Home = () => {
  const navigate = useNavigate();

  const subscriptionNavigation = useCallback(() => {
    navigate("/abonnements");
  }, [navigate]);

  return (
    <>
      <main className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis large_mobile:items-center large_mobile:justify-center">
        <div className="self-stretch flex flex-col pt-[0rem] px-[1.88rem] pb-[6.25rem] items-center justify-start lg:pb-[4.38rem] lg:box-border large_mobile:pl-[0.94rem] large_mobile:pr-[0.94rem] large_mobile:box-border Small_tablet:pb-[4.38rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl [background:linear-gradient(180deg,_#e7f4fa,_rgba(255,_255,_255,_0))] flex flex-col pt-[5rem] px-[0rem] pb-[0rem] items-center justify-start gap-[1.88rem] large_mobile:pt-[3.75rem] large_mobile:box-border">
            <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] sm:hidden Small_Mobile:hidden Iphone_SE:hidden large_mobile:hidden Small_tablet:hidden">
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">⌚️</div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Suivi des constantes
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center self-stretch justify-start">
                <div className="relative leading-[150%] font-medium Small_Desktop:text-[1.13rem]">
                  Coordination des soins
                </div>
              </div>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/arrowright.svg"
              />
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[150%] font-medium">🧡</div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[1.69rem] text-center text-[4rem] text-darkcyan large_mobile:pl-[0rem] large_mobile:box-border">
              <div className="self-stretch flex flex-row items-start justify-start large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border">
                <b className="flex-1 relative leading-[4.69rem] md:text-[2.81rem] md:leading-[3.44rem]">
                  <p className="m-0">
                    <span className="text-darkslateblue">{`La santé `}</span>
                    <span>{`connectée `}</span>
                  </p>
                  <p className="m-0 text-darkslateblue">{`à tous les parcours de soins `}</p>
                </b>
              </div>
              <div className="self-stretch h-[6.06rem] flex flex-col items-center justify-between text-[1.5rem] text-darkslateblue Small_Mobile:gap-[0rem] Iphone_SE:gap-[0rem]">
                <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] Iphone_SE:gap-[0.63rem] Iphone_SE:pb-[0rem] Iphone_SE:box-border large_mobile:flex-col large_mobile:pb-[0.63rem] large_mobile:box-border Small_tablet:flex Small_tablet:flex-col Small_tablet:gap-[0.63rem] Small_tablet:pb-[0.94rem] Small_tablet:box-border">
                  <div className="flex flex-row items-center justify-center">
                    <div className="relative font-medium md:text-[1.5rem] sm:text-[1.5rem] Small_Mobile:text-[1.25rem] Iphone_SE:text-[1.13rem]">
                      Première plateforme de
                    </div>
                  </div>
                  <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-white Iphone_SE:gap-[0.63rem] Iphone_SE:pl-[0.63rem] Iphone_SE:pr-[0.63rem] Iphone_SE:box-border">
                    <div className="relative font-semibold md:text-[1.25rem] Small_Mobile:text-[1.13rem]">{`suivi médical & paramédical`}</div>
                  </div>
                </div>
                <div className="flex flex-row items-center self-stretch justify-center">
                  <div className="relative Small_Mobile:text-[1.13rem] Iphone_SE:text-[1.13rem] Iphone_SE:leading-[2.81rem]">
                    <span className="font-medium">{`par `}</span>
                    <b className="[text-decoration:underline]">
                      objets connectés
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Newsletters />
        {/* <div className="self-stretch bg-white flex flex-col py-[3.13rem] px-[2.19rem] items-start justify-start text-[2.25rem] lg:items-start lg:justify-center md:flex-col large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border">
          <div className="self-stretch rounded-xl flex flex-row py-[3.13rem] px-[4.06rem] items-start justify-start gap-[5rem] border-[1px] border-solid border-darkorchid lg:gap-[2.5rem] lg:items-start lg:justify-center lg:pl-[2.81rem] lg:pr-[2.81rem] lg:box-border large_mobile:pl-[1.56rem] large_mobile:pr-[1.56rem] large_mobile:box-border Small_Desktop:flex-col Small_Desktop:gap-[1.88rem] Small_Desktop:items-center Small_Desktop:justify-center">
            <div className="flex-1 flex flex-row items-start justify-start Small_Desktop:flex-[unset] Small_Desktop:self-stretch">
              <b className="flex-1 relative leading-[3.13rem] Small_Mobile:text-[1.88rem] Small_Mobile:leading-[2.5rem] Small_Desktop:text-center">
                <span>{`Faites l’expérience du `}</span>
                <span className="text-darkcyan">parcours de soins</span>
                <span> connecté</span>
              </b>
            </div>
            <div className="flex flex-col items-end justify-start gap-[1.38rem] text-center text-[1.25rem]">
              <div className="self-stretch flex flex-row items-start justify-start gap-[2.88rem] lg:flex-row lg:gap-[0rem] large_mobile:flex-col">
                <div className="flex flex-row items-center justify-center">
                  <div className="relative leading-[2.19rem] font-medium inline-block w-[5.25rem] shrink-0 lg:text-[1rem]">
                    Je suis :
                  </div>
                </div>
                <div className="w-[32.44rem] flex flex-row items-start justify-between lg:w-auto lg:[align-self:unset] lg:gap-[0.63rem] large_mobile:flex-col">
                  <div className="flex flex-row items-center justify-center gap-[0.63rem] lg:gap-[0.31rem]">
                    <img
                      className="relative w-[1.5rem] h-[1.5rem]"
                      alt=""
                      src="/tickcircle.svg"
                    />
                    <div className="flex flex-row items-center justify-center">
                      <div className="relative leading-[2.19rem] font-medium lg:text-[1rem]">
                        Professionnels de santé
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center gap-[0.63rem] lg:gap-[0.31rem]">
                    <img
                      className="relative w-[1.5rem] h-[1.5rem]"
                      alt=""
                      src="/tickcircle1.svg"
                    />
                    <div className="flex flex-row items-center justify-center">
                      <div className="relative leading-[2.19rem] font-medium lg:text-[1rem]">
                        Patients ou Aidants
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-between text-white lg:gap-[1.44rem] large_mobile:flex-col large_mobile:gap-[0.63rem] large_mobile:items-center large_mobile:justify-center">
                <div className="rounded-lg bg-darkslateblue flex flex-row p-[0.94rem] items-start justify-start lg:gap-[0.63rem] lg:p-[0.63rem] lg:box-border">
                  <div className="relative font-semibold lg:text-[1rem] Iphone_SE:text-[1rem]">
                    Essayer Madoc Santé ✨
                  </div>
                </div>
                <div className="rounded-lg flex flex-row p-[0.94rem] items-start justify-start text-chocolate border-[2px] border-solid border-antiquewhite lg:p-[0.63rem] lg:box-border">
                  <div className="relative font-semibold lg:text-[1rem] Iphone_SE:text-[1rem]">
                    Demander une démo gratuite 👈
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="self-stretch flex flex-row flex-wrap p-[3.13rem] items-center justify-between large_mobile:gap-[3.13rem] large_mobile:pt-[1.88rem] large_mobile:px-[1.25rem] large_mobile:pb-[3.13rem] large_mobile:box-border Small_Desktop:gap-[3.13rem] Small_Desktop:items-center Small_Desktop:justify-center Small_tablet:pt-[1.25rem] Small_tablet:box-border">
          <div className="relative w-[6.44rem] h-[4.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[6.44rem] h-[4.06rem] object-cover"
              alt=""
              src="/1200pxlogotype-de-la-collectivite-territoriale-de-martinique-1@2x.png"
            />
          </div>
          <div className="relative w-[14.25rem] h-[3.63rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[14.25rem] h-[3.63rem] object-cover"
              alt=""
              src="/prosanteconnect-1@2x.png"
            />
          </div>
          <div className="relative w-[9.64rem] h-[1.91rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logosynapse@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[11.7rem] h-[2.27rem]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/logostellair@2x.png"
              />
            </div>
          </div>
          <div className="relative w-[8.56rem] h-[2.13rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[8.56rem] h-[2.13rem] object-cover"
              alt=""
              src="/logo-withings-1@2x.png"
            />
          </div>
          <div className="relative w-[9.75rem] h-[2.06rem]">
            <img
              className="absolute top-[0rem] left-[0rem] w-[9.75rem] h-[2.06rem] object-cover"
              alt=""
              src="/mssante1-2@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col py-[6.25rem] px-[3.13rem] items-center justify-center text-center lg:pl-[3.13rem] lg:box-border md:pb-[6.25rem] md:box-border sm:pb-[6.25rem] sm:box-border Small_Mobile:pt-[3.13rem] Small_Mobile:box-border Iphone_SE:pt-[3.13rem] Iphone_SE:pb-[6.25rem] Iphone_SE:box-border large_mobile:pl-[1.25rem] large_mobile:pr-[1.25rem] large_mobile:box-border Small_Desktop:pb-[6.25rem] Small_Desktop:box-border Small_tablet:pb-[3.13rem] Small_tablet:box-border">
          <div className="self-stretch flex flex-col py-[0rem] px-[9.38rem] items-center justify-start gap-[1.56rem] lg:pl-[3.13rem] lg:pr-[3.13rem] lg:box-border md:gap-[1.56rem] md:pl-[0rem] md:pr-[0rem] md:box-border large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border Small_Desktop:pl-[1.25rem] Small_Desktop:pr-[1.25rem] Small_Desktop:box-border Small_tablet:gap-[1.56rem]">
            <div className="rounded-lg bg-antiquewhite flex flex-row p-[0.94rem] items-start justify-start text-chocolate">
              <div className="relative font-semibold">Notre mission</div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-center text-[2.5rem]">
              <b className="flex-1 relative leading-[120%] md:text-[2rem] sm:text-[1.75rem] sm:text-center">
                Améliorons durablement le quotidien des soignants et la prise en
                charge des patients
              </b>
            </div>
            <div className="flex flex-row items-center self-stretch justify-start">
              <div className="flex-1 relative leading-[2.19rem] md:text-[1.13rem] sm:text-[1rem] sm:leading-[1.88rem]">
                <span className="font-medium">{`La solution Madoc Santé est conçue pour réinventer la prise en charge des `}</span>
                <b>populations vieillissantes et à mobilité réduite</b>
                <span className="font-medium">{`, soulager les médecins de villes, diminuer la `}</span>
                <b>saturation des urgences</b>
                <span className="font-medium">{` et des Ehpad et anticiper la `}</span>
                <b>prise en charge tardive</b>
                <span className="font-medium">{` des pathologies graves. Notre objectif est d’exploiter les avantages du numérique pour créer un `}</span>
                <b>parcours de soin collaboratif</b>
                <span className="font-medium">
                  {" "}
                  incluant le patient, le soignant et l’
                </span>
                <b>{`aidant `}</b>
                <span className="font-medium">à l’aide</span>
                <b> des objets connectés</b>
                <span className="font-medium">.</span>
              </div>
            </div>
            <div className="rounded-lg bg-darkslateblue flex flex-row p-[0.94rem] items-start justify-start text-white">
              <div className="relative font-semibold sm:text-[1rem] Small_Mobile:text-[0.94rem]">
                <a 
                style={{ textDecoration: "none" }}
                className="text-white"
                href="contact"
                > Demander une démo gratuite 🚀 </a>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-center text-[1rem] large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkcyan flex flex-row p-[0.94rem] items-start justify-start text-white">
              <div className="relative font-semibold">
                Professionnels de santé
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-[2rem]">
              <b className="flex-1 relative leading-[2.19rem] md:text-[1.75rem]">{`Pour une médecine précise & participative `}</b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-[1.25rem]">
              <div className="flex-1 relative leading-[150%] font-medium md:text-[1.13rem]">
                Coordination des soins médicaux et paramédicaux avec un logiciel
                métier inter-opérable pour une meilleure prise en charge des
                patients.
              </div>
            </div>
            <div className="rounded-lg bg-aliceblue flex flex-row p-[0.94rem] items-center justify-start gap-[0.63rem] text-darkcyan">
              <Link
                style={{ textDecoration: "none" }}
                to="/professionnels-de-sante"
                className="relative font-semibold text-darkcyan"
              >{`En savoir plus `}</Link>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/vuesaxlineararrowright.svg"
              />
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img-home-page-pro@2x.png"
          />
        </div>
        <div className="self-stretch flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-center text-[1rem] lg:gap-[1.88rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border large_mobile:flex-col large_mobile:pl-[3.75rem] large_mobile:box-border Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:pb-[1.25rem] Small_tablet:box-border">
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img-home-page-patient@2x.png"
          />
          <div className="flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-start gap-[1.88rem] lg:w-[34.38rem] large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:flex-col Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start text-white">
              <div className="relative font-semibold">Patients</div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-[2rem]">
              <b className="flex-1 relative leading-[2.19rem] md:text-[1.75rem]">{`Pour une médecine préventive & prédictive `}</b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-[1.25rem]">
              <div className="flex-1 relative leading-[150%] font-medium md:text-[1.13rem]">
                Collecte des données de santé par objets connectés et mise en
                valeur des informations médicales pour prévenir les risques de
                décompensations.
              </div>
            </div>
            <div className="rounded-lg bg-antiquewhite flex flex-row p-[0.94rem] items-center justify-start gap-[0.63rem] text-chocolate">
              <Link
                style={{ textDecoration: "none" }}
                to="/patients"
                className="relative font-semibold text-chocolate"
              >{`En savoir plus `}</Link>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/vuesaxlineararrowright1.svg"
              />
            </div>
            <div className="flex flex-row">
              <div className="relative text-[1.25rem]flex flex-col items-start justify-start px-4 py-4  font-semibold rounded-lg bg-darkslateblue">
                <div className= "pb-2">
                  <span className="text-white">{`Application Mobile`}</span>
                  <span className="text-[1.5rem]">📱</span>
                  <span className="font-semibold justify-center rounded bg-chocolate h-1/6 m-2 w-1/2 py-2 px-2 ml-2 text-center text-[0.88rem] text-white">{`Bientôt disponible`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row p-[6.25rem] items-center justify-start gap-[3.13rem] text-center text-[1rem] text-white large_mobile:flex-col Small_Desktop:pl-[1.88rem] Small_Desktop:pr-[1.88rem] Small_Desktop:box-border Small_tablet:flex-col Small_tablet:gap-[0rem] Small_tablet:pt-[1.25rem] Small_tablet:pb-[9.38rem] Small_tablet:box-border">
          <div className="self-stretch flex-1 rounded-xl flex flex-col py-[2.5rem] px-[1.88rem] items-start justify-center gap-[1.88rem] lg:w-[28.13rem] large_mobile:pl-[0rem] large_mobile:pr-[0rem] large_mobile:box-border large_mobile:flex-[unset] large_mobile:self-stretch Small_Desktop:self-stretch Small_Desktop:w-auto Small_tablet:pl-[0rem] Small_tablet:pr-[0rem] Small_tablet:box-border Small_tablet:flex-[unset] Small_tablet:self-stretch">
            <div className="rounded-lg bg-darkorchid flex flex-row p-[0.94rem] items-start justify-start">
              <div className="relative font-semibold">Aidants</div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-[2rem] text-darkslateblue">
              <b className="flex-1 relative leading-[2.19rem] md:text-[1.75rem]">{`Pour une médecine inclusive & innovante`}</b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-[1.25rem] text-darkslateblue">
              <div className="flex-1 relative leading-[150%] font-medium md:text-[1.13rem]">
                Accompagnement des personnes isolées et en fracture numérique
                dans leur suivi thérapeutique grâce à la télémédecine.
              </div>
            </div>
            <div className="rounded-lg bg-lavender flex flex-row p-[0.94rem] items-center justify-start gap-[0.63rem] text-darkorchid">
              <Link
                style={{ textDecoration: "none", 
                color: "9A54E7" }}
                to="/aidants"
                className="relative font-semibold"
              >{`En savoir plus `}</Link>
              <img
                className="relative w-[1.5rem] h-[1.5rem]"
                alt=""
                src="/vuesaxlineararrowright2.svg"
              />
            </div>

            <div className="flex flex-row">
              <div className="relative text-[1.25rem]flex flex-col items-start justify-start px-4 py-4  font-semibold rounded-lg bg-darkslateblue">
                <div className= "pb-2">
                  <span >{`Application Mobile`}</span>
                  <span className="text-[1.5rem]">📱</span>
                  <span className="font-semibold justify-center rounded bg-chocolate h-1/6 m-2 w-1/2 py-2 px-2 ml-2 text-center text-[0.88rem] text-white">{`Bientôt disponible`}</span>
                </div>
              </div>
            </div>
          </div>
          <img
            className="self-stretch flex-1 relative rounded-xl max-w-full overflow-hidden max-h-full object-cover large_mobile:flex-[unset] large_mobile:self-stretch Small_tablet:flex-[unset] Small_tablet:self-stretch"
            alt=""
            src="/img-home-page-aidant@2x.png"
          />
        </div>
        <div className="self-stretch flex flex-col py-[6.25rem] px-[5rem] items-center justify-start text-center text-white Small_tablet:pl-[1.25rem] Small_tablet:pt-[0rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl bg-darkslateblue flex flex-col py-[5.63rem] px-[3.75rem] items-center justify-start gap-[1.56rem] Iphone_SE:pt-[5.63rem] Iphone_SE:box-border large_mobile:py-[3.75rem] large_mobile:px-[1.88rem] large_mobile:box-border Small_tablet:py-[3.75rem] Small_tablet:px-[1.25rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-col items-start justify-start text-[2.5rem]">
              <b className="self-stretch relative leading-[120%] Small_tablet:text-[1.88rem]">
                Intégrez facilement Madoc Santé dans votre quotidien
              </b>
            </div>
            <div className="flex flex-row items-start self-stretch justify-start">
              <div className="flex-1 relative leading-[150%] font-medium large_mobile:text-[1.13rem] Small_tablet:text-[1rem]">
                <p className="m-0">{`Connectez vous en quelques minutes à notre plateforme et `}</p>
                <p className="m-0">
                  découvrez tous les avantages de la médecine connectée dans
                  votre parcours de soin
                </p>
              </div>
            </div>
            <div className="rounded-lg bg-chocolate flex flex-row p-[0.94rem] items-start justify-start">
              <div className="relative font-semibold Small_tablet:text-[1rem]">
              <a 
                style={{ textDecoration: "none" }}
                className="text-white"
                href="contact"
                > Demander une démo gratuite 🚀 </a>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col py-[6.25rem] px-[5rem] items-center justify-start text-[2.5rem] lg:pl-[2.5rem] lg:pr-[2.5rem] lg:box-border md:pt-[0rem] md:box-border Small_Mobile:pl-[1.25rem] Small_Mobile:pr-[1.25rem] Small_Mobile:box-border Small_tablet:pt-[0rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl flex flex-col p-[5rem] items-start justify-start gap-[2.44rem] border-[1px] border-solid border-darkorchid Small_tablet:gap-[2.44rem] Small_tablet:py-[3.13rem] Small_tablet:px-[1.88rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.63rem] lg:flex-col sm:gap-[3.75rem]">
              <div className="flex flex-col items-start justify-start">
                <b className="relative leading-[120%] Small_Mobile:text-[2.19rem]">
                  <p className="m-0">{`Pourquoi choisir `}</p>
                  <p className="m-0">Madoc Santé ?</p>
                </b>
              </div>
              <div className="flex-1 flex flex-row items-start justify-start gap-[5.63rem] text-[1.25rem] lg:flex-[unset] lg:self-stretch large_mobile:flex-col Small_tablet:gap-[1.88rem]">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                  <img
                    className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                    alt=""
                    src="/icon-accessible@2x.png"
                  />
                  <div className="flex flex-col items-start self-stretch justify-start">
                    <b className="self-stretch relative leading-[1.56rem]">
                      Accessible
                    </b>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                    <div className="flex-1 relative leading-[155%] font-medium">
                      Nous avons co-construit avec nos utilisateurs une solution
                      intuitive, adaptés à leurs besoins et qui continuera
                      d’évoluer avec eux.
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                  <img
                    className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                    alt=""
                    src="/icon-securite@2x.png"
                  />
                  <div className="flex flex-col items-start self-stretch justify-start">
                    <b className="self-stretch relative leading-[1.56rem]">
                      Sécurisé
                    </b>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                    <div className="flex-1 relative leading-[155%] font-medium">
                      Madoc Santé est un logiciel métier déployé sur un
                      hébergeur certifié HDS répondant à toutes les exigences en
                      vigueur pour la protection des données médicales.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[5.5rem] text-[1.25rem] lg:flex-col Small_tablet:gap-[2.5rem]">
              <div className="w-[47.25rem] flex flex-row items-start justify-start gap-[5.5rem] large_mobile:flex-col Small_tablet:self-stretch Small_tablet:w-auto Small_tablet:gap-[1.88rem]">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                  <img
                    className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                    alt=""
                    src="/icon-prosanteconnect@2x.png"
                  />
                  <div className="flex flex-col items-start self-stretch justify-start">
                    <b className="self-stretch relative leading-[1.56rem]">
                      Pro Santé Connect
                    </b>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                    <div className="flex-1 relative leading-[155%] font-medium">
                      Pro Santé Connect est une solution intégrée sur notre
                      plateforme afin de pouvoir authentifier les soignants
                      inscrits et garantir la sécurité des patients.
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                  <img
                    className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                    alt=""
                    src="/icon-interoperable@2x.png"
                  />
                  <div className="flex flex-col items-start self-stretch justify-start">
                    <b className="self-stretch relative leading-[1.56rem]">
                      Inter-opérable
                    </b>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                    <div className="flex-1 relative leading-[155%] font-medium">
                      Notre logiciel métier est compatible avec les outils
                      quotidiennement utilisés par les professionnels de santé
                      libéraux.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1.25rem] lg:flex-[unset] lg:self-stretch">
                <img
                  className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
                  alt=""
                  src="/icon-dmp@2x.png"
                />
                <div className="flex flex-col items-start self-stretch justify-start">
                  <b className="self-stretch relative leading-[1.56rem]">
                    DMP Compatible
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem]">
                  <div className="flex-1 relative leading-[155%] font-medium">
                    Nous avons à coeur de développer une solution qui permet à
                    tous nos patients de disposer et d’extraire leurs données de
                    santé à tout moment.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col py-[6.25rem] px-[7.5rem] items-center justify-start text-[1.5rem] text-darkcyan md:pt-[0rem] md:box-border Small_tablet:pl-[1.25rem] Small_tablet:pt-[0rem] Small_tablet:pr-[1.25rem] Small_tablet:box-border">
          <div className="self-stretch rounded-xl bg-aliceblue flex flex-col py-[3.75rem] px-[7.5rem] items-center justify-start gap-[4.13rem] sm:gap-[2.5rem] Small_tablet:pl-[1.88rem] Small_tablet:pr-[1.88rem] Small_tablet:box-border">
            <div className="self-stretch flex flex-row items-center justify-center text-center text-[2.5rem] text-darkslateblue">
              <b className="flex-1 relative leading-[120%] sm:text-[1.88rem]">
                <span>{`Un logiciel métier qui accompagne les systèmes de santé libéraux vers la `}</span>
                <span className="text-chocolate">transformation digitale</span>
              </b>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon-cabinet@2x.png"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_tablet:text-[1.25rem]">
                    Cabinets médicaux indépendants
                  </b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] Small_tablet:text-[1rem]">
                    <span className="font-medium">{`Simplifier et `}</span>
                    <b>centraliser</b>
                    <span className="font-medium">{` les activités quotidiennes des professionnels libéraux pour alléger leur charge de travail et `}</span>
                    <b>automatiser</b>
                    <span className="font-medium"> leurs tâches.</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[2.5rem] large_mobile:flex-col large_mobile:gap-[1.25rem] large_mobile:items-start large_mobile:justify-start">
              <img
                className="relative w-[4.44rem] h-[4rem] object-cover"
                alt=""
                src="/icon-ephad@2x.png"
              />
              <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[1.25rem] large_mobile:flex-[unset] large_mobile:self-stretch">
                <div className="flex flex-row items-start self-stretch justify-start">
                  <b className="relative leading-[1.56rem] Small_tablet:text-[1.25rem]">{`Centre de santé & EPHAD`}</b>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1.13rem] text-darkslateblue">
                  <div className="flex-1 relative leading-[155%] Small_tablet:text-[1rem]">
                    <span className="font-medium">{`Améliorer la `}</span>
                    <b>coordination des soins</b>
                    <span className="font-medium">{` des équipes médicales avec un `}</span>
                    <b>outil de traçabilité</b>
                    <span className="font-medium">{` adapté et sécurisé afin de `}</span>
                    <b>limiter la perte de données</b>
                    <span className="font-medium">.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
