import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();
  
  const onTitreContainerClick = useCallback((path) => {
    navigate("/professionnels-de-sante");
  }, [navigate]);

  const onTitreContainer1Click = useCallback(() => {
    navigate("/patients");
  }, [navigate]);

  const onTitreContainer2Click = useCallback(() => {
    navigate("/aidants");
  }, [navigate]);

  return (
    <div className="relative rounded-t-none rounded-b-xl bg-white shadow-[0px_50px_50px_rgba(9,_38,_81,_0.1)] flex flex-col py-[0.94rem] px-[1.25rem] box-border items-start justify-start gap-[0.63rem] max-w-full max-h-full overflow-auto text-left text-[1rem] text-darkslateblue font-metropolis">
      <div
        className="w-[16.81rem] flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
        onClick={onTitreContainerClick}
      >
        <img
          className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
          alt=""
          src="/icon-prosanteconnect1@2x.png"
        />
        <div className="self-stretch flex-1 flex flex-col items-start justify-center">
          <div className="self-stretch flex flex-row items-start justify-start">
            <div className="flex-1 relative leading-[155%] font-medium">
              Professionnels de santé
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-[16.81rem] flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
        onClick={onTitreContainer1Click}
      >
        <img
          className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
          alt=""
          src="/icon-prosanteconnect2@2x.png"
        />
        <div className="self-stretch flex-1 flex flex-col items-start justify-center">
          <div className="self-stretch flex flex-row items-start justify-start">
            <div className="flex-1 relative leading-[155%] font-medium">
              Patients
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-[16.81rem] flex flex-row items-center justify-start gap-[1.25rem] cursor-pointer"
        onClick={onTitreContainer2Click}
      >
        <img
          className="rounded-3xs w-[2.94rem] h-[2.94rem] object-cover"
          alt=""
          src="/icon-prosanteconnect3@2x.png"
        />
        <div className="self-stretch flex-1 flex flex-col items-start justify-center">
          <div className="self-stretch flex flex-row items-start justify-start">
            <div className="flex-1 relative leading-[155%] font-medium">
              Aidants
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
