import React from "react";

const BillingPack = () => {
  return (
    <section>
      <div className="mx-auto">
        {/* div séparator + block */}
        <div className="flex _lg:ml-4 _lg:space-x-6">
          {/* div main blocks*/}
          <div className="max-w-[290px] mx-auto">
            {/* div block 1 bg orange */}
            <div className="h-[215px] bg-gradient-to-b  from-[#F1A165] to-[#E67A29] text-white pl-6 pr-9 pt-4 rounded-lg mb-9">
              <h3 className="text-3xl">Pack Facturation</h3>
              <h3 className="text-4xl text-right mt-24 font-bold _lg:text-5xl">
                119,99€<span className="text-base">/mois</span>
              </h3>
            </div>
            {/* div main block 2 */}
            <div className="flex flex-col pl-2 space-y-3">
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg done green"
                />
                <p>Identification forte</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg done green"
                />
                <p>Intégrer l’équipe médicale</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg done green"
                />
                <p>Accès temporaire aux données du patient</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg done green"
                />
                <p>Accès aux traitements à jour</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg done green"
                />
                <p>
                  Accès aux demandes de remplacement et création d'une offre de
                  remplacement
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg done green"
                />
                <p>Planning des évènements publicitaires à venir</p>
              </div>
              <div className="py-4 mx-auto">
                <img
                  src="/separator-orange.svg"
                  alt="svg separator orange"
                />
              </div>
            </div>
            {/* div main block 3 */}
            <div className="flex flex-col pl-2 space-y-3">
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg not done red"
                />
                <p>e-Planning</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg not done red"
                />
                <p>Accès Madoc notify</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg not done red"
                />
                <p>Accès Madoc follow-up</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg not done red"
                />
                <p>Création de compte-rendu et de transmission</p>
              </div>
              <div className="py-4 mx-auto">
                <img
                  src="/separator-orange.svg"
                  alt="svg separator orange"
                />
              </div>
            </div>
            {/* div main block 4 */}
            <div className="flex flex-col pl-2 space-y-3">
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg not done red"
                />
                <p>Plateforme de gestion des facturations</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/green.svg"
                  alt="svg not done red"
                />
                <p>Leasing d’un lecteur de facturation FSE 3G/4G</p>
              </div>
              <div className="py-4 mx-auto">
                <img
                  src="/separator-orange.svg"
                  alt="svg separator orange"
                />
              </div>
            </div>
            {/* div main block 5 */}
            <div className="flex flex-col pl-2 space-y-3">
              <div className="flex items-center space-x-4">
                <img
                  src="/red.svg"
                  alt="svg not done red"
                />
                <p>Solution d’aide à la prescription</p>
              </div>
              <div className="flex items-center space-x-4">
                <img
                  src="/red.svg"
                  alt="svg not done red"
                />
                <p>Recherche des interactions médicamenteuses</p>
              </div>
            </div>
          </div>
          {/* div séparator */}
          <div className="hidden _lg:block">
            <img
              src="/separator-gray.svg"
              alt="svg separator gray"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BillingPack;
