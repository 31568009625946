import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import { useEffect } from "react";
import Patients from "./pages/Patients";
import Professionals from "./pages/Professionals";
import Helpers from "./pages/Helpers";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Subscriptions from "./pages/Subscriptions";
import Header from "./components/Header";
import NewHeader from "./components/NewHeader";
import Footer from "./components/Footer";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
      <NewHeader />
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/patients"
          element={<Patients />}
        />
        <Route
          path="/professionnels-de-sante"
          element={<Professionals />}
        />
        <Route
          path="/aidants"
          element={<Helpers />}
        />
        <Route
          path="/abonnements"
          element={<Subscriptions />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />
        <Route
          path="/a-propos"
          element={<About />}
        />
      </Routes>
      <Footer />
    </>
  );
}
export default App;
