import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FormContact = () => {
  const phoneRegExp = /^(0)[1-9]([0-9]{2}){4}$/;
  const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const schema = yup.object().shape({
    name: yup.string().default('').required("Ce champ est requis."),
    lastName: yup.string().default('').required("Ce champ est requis."),
    email: yup
      .string()
      .default('')
      .matches(emailRegExp, "Adresse email invalide.")
      .required("Ce champ est requis."),
    phone: yup
      .string()
      .default('')
      .matches(phoneRegExp, "Numéro de téléphone invalide.")
      .required("Ce champ est requis."),
    compagny: yup.string().default(''),
    description: yup.string().default('').max(244).required("244 caractères maximum."),
    checkbox: yup.bool().oneOf([true], "You must accept the terms and conditions")
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // toast sucess
  const notifySucess = () => {
    console.log("notify");
    toast.success("Votre message a été envoyée", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };
  // toast error
  const notifyError = () => {
    console.log("notify");
    toast.error("Erreur veuillez réessayer", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const API_KEY = process.env.REACT_APP_FORM_API_KEY;

  const onSubmit = (data) => {
    const body = {
      query: `
        query  {
          contactMadoc(input: {
            compagny: "${data.compagny}", 
            description: "${data.description}", 
            email: "${data.email}", 
            firstname: "${data.name}", 
            lastname: "${data.lastName}", 
            phone: "${data.phone}"}) 
          {
            code
          }
      }`,
    };
    const options = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
    };
    axios
      .post(
        "https://lnv4hzv6hvaftcbs42o2vwhidq.appsync-api.eu-west-3.amazonaws.com/graphql",
        body,
        options
      )
      .then((response) => {
        console.log(response.data);
        notifySucess();
      })
      .catch((error) => {
        console.log(error.data);
        notifyError();
      });
    reset();
  };

  return (
    <section className="pt-8 mx-4">
      <div className="container mx-auto">
        {/* div main titles + form */}
        <div className="flex flex-col w-auto _lg:flex-row _lg:space-x-14">
          {/* div blocks titles */}
          <div className="max-h-[350px] bg-blouse rounded-xl py-4 px-6 mb-12 flex flex-col space-y-6 _lg:py-12 _lg:mb-0 _lg:max-w-[403px]">
            <h2 className="text-[28px] leading-9 font-semibold text-[#092651] _lg:leading-[54px] _lg:text-[45px]">
              Contactez-nous
            </h2>
            <h3 className="text-xl font-semibold text-[#092651]">
              Nous sommes à votre écoute pour répondre à toutes vos questions ou
              remarques.
            </h3>
            <p className="text-[#092651] font-medium text-base">
              Nous vous proposons une réponse par mail ou par échange
              téléphonique dans un délai de 24h pour que nous puissions vous
              aider à réaliser rapidement votre projet.
            </p>
          </div>
          {/* div form */}
          <div className="w-full">
            <h3 className="mb-8 text-xl font-semibold text-[#092651]">
              Vos coordonnées de contact
            </h3>
            {/* form */}
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* div name + lastname */}
              <div className="flex flex-col _lg:flex-row _lg:space-x-5">
                {/* div name */}
                <div className="flex flex-col w-full mb-4 space-y-3">
                  <label
                    for="firstname"
                    className="text-[#092651] font-medium text-base"
                  >
                    Votre prénom
                  </label>
                  <input
                    id="firstname"
                    name="firstname"
                    className="border border-[#E7F4FA] rounded-lg py-4 pl-3 focus:outline-none _lg:max-w-[387px]"
                    type="text"
                    autoComplete="none"
                    {...register("name")}
                  />
                  <p className="text-xs font-medium text-red-500">
                    {errors.name?.message}
                  </p>
                </div>
                {/* div last name */}
                <div className="flex flex-col w-full mb-4 space-y-3">
                  <label
                    for="lastname"
                    className="text-[#092651] font-medium text-base"
                  >
                    Votre nom
                  </label>
                  <input
                    id="lastname"
                    name="lastname"
                    className="border border-[#E7F4FA] rounded-lg py-4 pl-3  focus:outline-none _lg:max-w-[387px]"
                    type="text"
                    autoComplete="none"
                    {...register("lastName")}
                  />
                  <p className="text-xs font-medium text-red-500">
                    {errors.lastName?.message}
                  </p>
                </div>
              </div>
              {/* div mail + mobile */}
              <div className="flex flex-col _lg:flex-row _lg:space-x-5">
                {/* div mail */}
                <div className="flex flex-col w-full mb-4 space-y-3">
                  <label
                    for="email"
                    className="text-[#092651] font-medium text-base"
                  >
                    Votre adresse mail
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="border border-[#E7F4FA] rounded-lg py-4 pl-3  focus:outline-none _lg:max-w-[387px]"
                    type="email"
                    autoComplete="none"
                    {...register("email", {
                      pattern: {
                        value: /^\S+@\S+$/i,
                      },
                    })}
                  />
                  <p className="text-xs font-medium text-red-500">
                    {errors.email?.message}
                  </p>
                </div>
                {/* div mobile */}
                <div className="flex flex-col w-full mb-4 space-y-3">
                  <label
                    for="phone"
                    className="text-[#092651] font-medium text-base"
                  >
                    Votre téléphone mobile
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    className="border border-[#E7F4FA] rounded-lg py-4 pl-3  focus:outline-none _lg:max-w-[387px]"
                    type="tel"
                    autoComplete="none"
                    {...register("phone", {
                      minLength: 6,
                      maxLength: 12,
                      pattern: {
                        value: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                      },
                    })}
                  />
                  <p className="text-xs font-medium text-red-500">
                    {errors.phone?.message}
                  </p>
                </div>
              </div>
              {/* div society */}
              <div className="flex flex-col mb-4 space-y-3">
                <label
                  for="company"
                  className="text-[#092651] font-medium text-base"
                >
                  Nom de la société (facultatif)
                </label>
                <input
                  id="company"
                  name="company"
                  className="border border-[#E7F4FA] rounded-lg py-4 pl-3 focus:outline-none"
                  type="text"
                  autoComplete="none"
                  {...register("compagny")}
                />
              </div>
              {/* div need */}
              <div className="flex flex-col mb-4 space-y-3">
                <label
                  for="description"
                  className="text-[#092651] font-medium text-base"
                >
                  Décrivez votre besoin
                </label>
                <textarea
                  id="description"
                  name="description"
                  className="max-w-[801px] border border-[#E7F4FA] rounded-lg pl-3 pt-2 h-[345px] _lg:h-[247px] focus:outline-none"
                  autoComplete="none"
                  {...register("description")}
                />
                <p className="text-xs font-medium text-red-500">
                  {errors.description?.message}
                </p>
              </div>
              {/* div contract */}
              <div className="flex flex-row items-center mb-10 space-x-3">
                <input
                  className="w-7 h-7 _sm:w-5 _sm:h-5 _md:w-4 _md:h-4 _lg:w-4 _lg:h-4 text-[#092651]"
                  type="checkbox"
                  autoComplete="none"
                  {...register("checkbox")}
                />
                <label className="text-[#092651] font-medium text-base">
                  J’ai lu et accepté les termes de la politique de
                  confidentialité de Madoc Santé
                </label>
              </div>
              <div className="button max-w-[221px] font-semibold text-center py-4 bg-[#E67A29] rounded-lg">
                <button
                  className="text-base text-white bg-transparent"
                  type="submit"
                >
                  Envoyez ma demande
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* tast conterainer important pour le tast */}
      <ToastContainer />
    </section>
  );
};

export default FormContact;
