import React from "react";
import FormContact from "../components/FormContact";

const Contact = () => {
  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-[1.25rem] text-darkslateblue font-metropolis">
        <FormContact />
      </div>
    </>
  );
};

export default Contact;
